import React, {memo, useEffect, useState} from 'react';
import {Avatar, Button, Card, Col, Flex, Form, InputNumber, Modal, Row, TimePicker, Tooltip, Typography, Tag} from 'antd';
import {createAcronym} from '../Charters/CharterItinerary';
import LoadMessage from '../../components/LoadMessage';
import SelectEmployeeView, {employeeName} from './SelectEmployeeView';
import SelectVehicleView from './SelectVehicleView';
import ShareModal from '../../components/ShareModal';
import {CloseOutlined, EyeOutlined} from '@ant-design/icons';
import {ReactComponent as Print} from '../../assets/icons/Print.svg';
import {ReactComponent as Edit} from '../../assets/icons/Edit.svg';
import ShiftBatPrintModal from '../ShiftBat/ShiftBatPrintModal';
import config from '../../config';
import {dayjsToSecsSinceMidnight, secsSinceMidnightToDayjs} from '../../model/timeFilter';
import { toHrsMinsSecs } from '../../libs/formatLib';
import { debounce } from 'lodash';
const Text = Typography.Text;

const BearingMarker = memo(({rotation}) => {
    return (
        <div className="VehicleBearing">
            <div className="VehicleBearingMarker" style={{transform: `rotate(${rotation}deg)`}}></div>
        </div>
    );
});

export const EmployeeAvatar = memo(({employeeId, employeeName, employeeAvatarUrl, statusColour = '#C1C5CE'}) => {
    return <Avatar key={`employee-avatar-${employeeId}`}
                   style={{backgroundColor: statusColour, verticalAlign: 'middle'}}
                   size={28}
                   src={employeeAvatarUrl}>{createAcronym(employeeName)}</Avatar>;
});

export const Allocation = memo(({
                                    employeeId,
                                    employeeName,
                                    employeeAvatarUrl,
                                    vehicleName,
                                    statusColour,
                                    onClick
                                }) => (
    <div
        className={`allocation-item ${employeeName === '?' ? 'driver-error' : ''} ${vehicleName ? '' : 'vehicle-error'}`}
        onClick={onClick}>
        <div className="row RouteTitle flex-nowrap align-items-center">
            <div className={`RouteLogo`}>
                <EmployeeAvatar employeeId={employeeId} employeeName={employeeName}
                                employeeAvatarUrl={employeeAvatarUrl}/>
                <div className="driver-name">{employeeName}</div>
            </div>

            <span className="RouteNumber" style={{backgroundColor: statusColour}}>{vehicleName}</span>
        </div>
    </div>));


export const AllocationModalDiv = memo(({
                                            scenario,
                                            roster,
                                            workItem,
                                            isDefault = false,
                                            employeeId,
                                            vehicleId,
                                            employeeData,
                                            allVehicles,
                                            save, saveRoster,
                                            allStops, allRoutes, allCharters, schedules, operator,
                                            isOpen, setIsOpen,
                                            isDefaultAllocation,
                                            onSelectWorkItem
                                        }) => {

    const [selectedEmployee, setSelectedEmployee] = useState(employeeData.getEmployee(employeeId));
    const [selectedVehicle, setSelectedVehicle] = useState(allVehicles?.[vehicleId]);
    const [printShiftBat, setPrintShiftBat] = useState(false);
    const [showAllocation, setShowAllocation] = useState(false);
    const [reallocate, setReallocate] = useState(false);
    const [unsaved, setUnsaved] = useState(false);

    useEffect(() => {
        const _unsaved = selectedEmployee?.employeeID !== employeeId || selectedVehicle?.vehicleId !== vehicleId
        setUnsaved(_unsaved);
        setReallocate(_unsaved);
    }, [selectedEmployee, selectedVehicle, employeeId, vehicleId]);

    useEffect(() => {
        const show = isDefault && !selectedEmployee && !selectedVehicle;
        setShowAllocation(!selectedEmployee || !selectedVehicle || show || unsaved)
    }, [workItem, selectedEmployee, selectedVehicle, isDefault, unsaved]);

    const onClose = () => {
        setReallocate(false);
        setIsOpen(false);
        // set to default if not saved
        setSelectedEmployee(employeeData.getEmployee(employeeId))
        setSelectedVehicle(allVehicles?.[vehicleId])
    };

    const [isStaffUnavailable, setIsStaffUnavailable] = useState(false);
    const [isVehicleUnavailable, setIsVehicleUnavailable] = useState(false);

    const handleStaffAvailability = (isUnavailable) => {
        setIsStaffUnavailable(isUnavailable);
    };

    const handleVehicleAvailability = (isUnavailable) => {
        setIsVehicleUnavailable(isUnavailable);
    };

    const debouncedSaveRoster = debounce(saveRoster, 500);

    const showUnallocatedAll = !workItem && roster.workItems.length > 1 && employeeId;

    return (
        <>{employeeData && allVehicles ? <>
                <Modal
                    open={isOpen}
                    width={800}
                    style={{top: 50}}
                    onCancel={onClose}
                    onClose={onClose}
                    closable={true}
                    destroyOnClose
                    footer={[
                        <Button className="btn-secondary" key="employee-close-btn" onClick={onClose}>Cancel</Button>,
                        <Button key="employee-save-btn" type="primary" className="btn-primary"
                                disabled={!selectedEmployee && !selectedVehicle}
                                onClick={() => {
                                    save({employee: selectedEmployee, vehicle: selectedVehicle});
                                    if (workItem) {
                                        saveRoster(roster);
                                    }
                                    setIsOpen(false);
                                }}>Save</Button>
                    ]}
                >
                    <div className="ant-modal-header d-flex justify-content-between hide-print" style={{paddingRight: '28px', marginBottom: '25px'}}>
                        {!workItem && selectedEmployee && selectedVehicle ?
                        <>
                            <div>
                                <div className="ant-modal-title">{!isDefaultAllocation ? 'User Allocation' : 'Default Allocation'}</div>
                            </div>
                        </>: <></>}
                        {showUnallocatedAll && <>
                            <div className="d-flex align-items-center" style={{gap: '10px'}}>
                                <Tooltip
                                    title={`Remove all work items from ${selectedEmployee.firstName} ${selectedEmployee.lastName} and return to Unallocated.`}>
                                    <Button className={'info-icon-button light btn-error'}
                                        icon={<CloseOutlined/>}
                                        onClick={() => {
                                            roster.removeAllWorkItemsFromEmployee(employeeId);
                                            save({employee: null, vehicle: null});
                                            saveRoster(roster);
                                            setIsOpen(false);
                                        }}>Unallocate All</Button>
                                </Tooltip>
                            </div>
                        </>}
                        {workItem && <>
                            <div>
                                <div className="ant-modal-title">{workItem ? workItem.workItemName : roster.rosterName}</div>
                                {workItem && <span>{workItem.startTimeFormatted} - {workItem.endTimeFormatted}</span>}
                            </div>
                            <div className="d-flex align-items-center" style={{gap: '10px'}}>
                                <a className="info-icon-button light"
                                    href={`${config.app.BASE_URL}/shiftbats/${workItem.duty?.shiftBatId}`}
                                    target={'_blank'} rel="noreferrer"><EyeOutlined/>View duty</a>
                                <ShiftBatPrintModal
                                    allStops={allStops}
                                    allRoutes={allRoutes}
                                    allCharters={allCharters}
                                    apiKey={operator?.operatorId}
                                    shiftBat={workItem.duty}
                                    visible={printShiftBat}
                                    schedules={schedules}
                                    setVisible={setPrintShiftBat}
                                    workItem={workItem}
                                    employeeData={employeeData}
                                    allVehicles={allVehicles}
                                    />
                                <Button className="info-icon-button light" icon={<Print style={{width: 16}}/>}
                                        onClick={() => setPrintShiftBat(!printShiftBat)}>Print</Button>
                                <div className="info-icon-button-wrap light"><ShareModal shiftBat={workItem.duty}/></div>
                                <Tooltip
                                    title={`Remove work item from ${roster.rosterName} and return to Unallocated.`}>
                                    <Button className={'info-icon-button light btn-error'}
                                            icon={<CloseOutlined/>}
                                            onClick={() => {
                                                roster.removeWorkItem(workItem);
                                                saveRoster(roster);
                                                onSelectWorkItem && onSelectWorkItem(null)
                                            }}>Unallocate</Button></Tooltip>
                            </div>
                        </>}
                    </div>
                    {!workItem && selectedEmployee && selectedVehicle ?
                        <Form layout="vertical" className="modal-info pb-4 hide-print">
                            <Row gutter={[30, 30]} className="mb-2">
                                <Col xs={24} lg={16} className="justify-content-center">
                                    <label>Assigned driver & vehicle</label>
                                    {selectedEmployee && selectedVehicle ? <div className="d-flex w-100 align-items-center" style={{gap: 25}}>
                                        <div className="RouteLogo justify-content-start"
                                             style={{border: 'none', gap: '8px', width: 'auto'}}>
                                            <EmployeeAvatar employeeId={employeeId}
                                                            employeeName={selectedEmployee?.firstName + ' ' + selectedEmployee?.lastName}
                                                //employeeAvatarUrl={employeeAvatarUrl}
                                            />
                                            <div className="driver-name"
                                                 style={{fontSize: 15}}>{selectedEmployee?.firstName} {selectedEmployee?.lastName}</div>
                                        </div> :
                                        <div className="vehicle-name">{selectedVehicle?.vehicleName}</div>
                                        <Button type="primary"
                                                className={`icon-button btn-sm ${showAllocation ? 'active' : ''}`}
                                                onClick={() => {
                                                    setReallocate(true);
                                                }}>Reallocate</Button>
                                    </div> :
                                        <Text type={'danger'}>Please allocate driver and vehicle below</Text>}
                                </Col>
                            </Row>
                        </Form>
                    : <></>}
                    {workItem && <>
                        <div className='hide-print-wrap'>
                            <Form layout="vertical" className="modal-info pb-4">
                                <Row gutter={[30, 30]} className="mb-2">
                                    <Col xs={24} lg={16} className="justify-content-center">
                                        <label>Assigned driver & vehicle</label>
                                        {selectedEmployee && selectedVehicle ? <div className="d-flex w-100 align-items-center" style={{gap: 25}}>
                                            <div className="RouteLogo justify-content-start"
                                                style={{border: 'none', gap: '8px', width: 'auto'}}>
                                                <EmployeeAvatar employeeId={employeeId}
                                                                employeeName={selectedEmployee?.firstName + ' ' + selectedEmployee?.lastName}
                                                    //employeeAvatarUrl={employeeAvatarUrl}
                                                />
                                                <div className="driver-name"
                                                    style={{fontSize: 15}}>{selectedEmployee?.firstName} {selectedEmployee?.lastName}</div>
                                            </div> :
                                            <div className="vehicle-name">{selectedVehicle?.vehicleName}</div>
                                            <Button type="primary"
                                                    className={`icon-button btn-sm ${showAllocation ? 'active' : ''}`}
                                                    onClick={() => {
                                                        setReallocate(true);
                                                    }}>Reallocate</Button>
                                        </div> :
                                            <Text type={'danger'}>Please allocate driver and vehicle below</Text>}
                                    </Col>
                                    {/* <Col xs={24} lg={8}>
                                        <label>Assigned vehicle</label>
                                        <div className="w-100">{selectedVehicle?.vehicleName}</div>
                                    </Col> */}
                                    <Col xs={24} lg={8}>
                                        <label>Scheduled hours</label>
                                        <div className="w-100">
                                            {(workItem?.scheduledHours || 0).toFixed(2)}
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <label>Actual start</label>
                                        <div className="w-100">
                                            <TimePicker value={secsSinceMidnightToDayjs(workItem?.actualStart || 0)}
                                                        needConfirm={false} showNow={false} format={'HH:mm'}
                                                        onChange={time => {
                                                            workItem.actualStart = dayjsToSecsSinceMidnight(time);
                                                            saveRoster(roster);
                                                        }} className="w-100"/>
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <label>Actual end</label>
                                        <div className="w-100">
                                            <TimePicker value={secsSinceMidnightToDayjs(workItem?.actualEnd || 0)}
                                                        needConfirm={false} showNow={false}
                                                        format={'HH:mm'} onChange={time => {
                                                workItem.actualEnd = dayjsToSecsSinceMidnight(time);
                                                saveRoster(roster);
                                            }} className="w-100"/>
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <label>Actual pay hours</label>
                                        <div className="w-100">
                                            <InputNumber value={workItem.actualPayHours?.toFixed(2) || 0} onChange={value => {
                                                workItem.actualPayHours = value;
                                                debouncedSaveRoster(roster);
                                            }} className="w-100"/>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </>}
                    {(showAllocation || reallocate) ?
                        <div className="pb-3 hide-print">
                            <div className="ant-modal-header mb-3">
                                <div className="ant-modal-title">{workItem || !isDefaultAllocation ? '' : 'Default'} Allocation</div>
                            </div>
                            <Flex style={{gap: '30px'}} className={isStaffUnavailable || isVehicleUnavailable ? 'showUnavailable' : 'hideUnavailable'}>
                                <Card title={'Select Employee'} style={{width: '50%'}} className='body-100'>
                                    <SelectEmployeeView scenario={scenario} roster={roster} isDefault={isDefault}
                                                        selectedEmployee={selectedEmployee}
                                                        setSelectedEmployee={setSelectedEmployee}
                                                        employeeData={employeeData} onAvailabilityChange={handleStaffAvailability}
                                                        workItem={workItem}/>
                                </Card>
                                <Card title={'Select Vehicle'} style={{width: '50%'}} className='body-100'>
                                    <SelectVehicleView scenario={scenario} roster={roster} isDefault={isDefault}
                                                       selectedVehicle={selectedVehicle}
                                                       setSelectedVehicle={setSelectedVehicle}
                                                       allVehicles={allVehicles} onAvailabilityChange={handleVehicleAvailability}
                                                       workItem={workItem}/>
                                </Card>
                            </Flex>
                        </div> : null}
                </Modal> </> :
            <LoadMessage title="Loading staff..."/>
        }
        </>
    );
});

export const AllocationModal = memo(({...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const {employeeData, allVehicles, employeeId, vehicleId,} = props;

    return (
        <>{employeeData && allVehicles ? <>
                <Allocation onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsOpen(true);
                }} employeeId={employeeData.getEmployee(employeeId)?.employeeID}
                            employeeName={employeeName(employeeData.getEmployee(employeeId))}
                            vehicleName={allVehicles[vehicleId]?.vehicleName}/>
                <AllocationModalDiv {...props} isOpen={isOpen} setIsOpen={setIsOpen}/> </> :
            <LoadMessage title="Loading staff..."/>
        }
        </>
    );
});

export const AllocationBtnModal = memo(({...props}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (<>
        <Tooltip title="Edit" placement="right" mouseEnterDelay={0.9}>
            <Button icon={<Edit/>} onClick={() => setIsOpen(!isOpen)}/>
        </Tooltip>
        <AllocationModalDiv {...props} isOpen={isOpen} setIsOpen={setIsOpen}/>
    </>);
});

export const AlternativeAllocationModal = memo(({...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const {employeeData, allVehicles, employeeId, vehicleId, 
        scenario, roster, rosterData, operator, 
        isDefaultAllocation = true, rosterEmployees} = props;

    const complianceForEmployee = [];
    if (operator?.opts?.compliance?.accept) {
        rosterData.forEach(r => {
            r.getWorkItemsForEmployee(employeeId).forEach(wi => {
                if (wi.compliance) {
                    complianceForEmployee.push(...wi.compliance);
                }
            });
        });
    }
    const employee = employeeData.getEmployee(employeeId);
    const totalTime = toHrsMinsSecs(scenario.getTotalTime('employee', employeeId));
    const rosterTime = toHrsMinsSecs(roster.getTotalTimeForEmployee(employeeId));
    const name = employeeName(employee) || 'Unknown';

    const isDefaultNotSet = !employee && !roster.defaultEmployeeId;
    const isDefault = isDefaultNotSet || roster.defaultEmployeeId === employeeId;
    const showDefaultBadge = isDefault && (isDefaultNotSet || rosterEmployees?.length > 1);

    return (
        <>
            <div
                className={`allocation-item`}
                onClick={() => {
                    setIsOpen(true)
                }}
            >
                <Tooltip
                    overlayClassName="fixed-tooltip"
                    mouseEnterDelay={0.9}
                    title={
                        <div>
                            <div>
                                {name} {rosterTime}
                            </div>
                            <div>Weekly total: {totalTime}</div>
                            <div>
                                {complianceForEmployee.map((c) => (
                                    <div className="mt-3">{c}</div>
                                ))}
                            </div>
                        </div>
                    }
                    placement="bottom"
                >
                    <Avatar
                        size={24}
                        style={{
                            backgroundColor: complianceForEmployee?.length ? "#FFE6E6" : "#C1C5CE",
                            color: complianceForEmployee?.length ? "#FF4D4D" : null,
                            borderColor: complianceForEmployee?.length ? "#FDCCC9" : null,
                        }}
                    >
                        {createAcronym(name)}
                    </Avatar>
                    <div>
                        {/* {showDefaultBadge && <Tag className='tag-sm'>Default</Tag>} @TO DO: show only if there are more than one driver */}
                        <div className="driver-name">{name}</div>
                        <div className="roster-time">{rosterTime}</div>
                    </div>
                </Tooltip>
            </div>
            <AllocationModalDiv {...props} isOpen={isOpen} setIsOpen={setIsOpen} isDefaultAllocation={isDefaultAllocation}/>
        </>
    );
})