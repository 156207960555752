import React, {useCallback, useMemo, useState} from 'react';
import {Avatar, Button, Empty, Flex, Popover, Table, Tooltip} from 'antd';
import './Rosters.css';
import {toHrsMinsSecs} from '../../libs/formatLib';
import {WeeklyRoster} from '../../model/roster';
import EditPopover from '../../components/EditPopover';
import {createAcronym} from '../Charters/CharterItinerary';
import {AllocationModal, AlternativeAllocationModal} from './AllocationModal';
import {DeleteOutlined} from '@ant-design/icons';
import {employeeName} from './SelectEmployeeView';
import {capitalize, find} from 'lodash';
import dayjs from '../../dayjs';
import {WorkItemForDay} from './WorkItemForDay';
import {DriverCompliance} from '../../model/compliance';
import {keys} from 'lodash/object';
import {ReactComponent as Check} from '../../assets/icons/Check.svg';
import ShiftBatPrintModal from '../ShiftBat/ShiftBatPrintModal';
import {ReactComponent as Print} from '../../assets/icons/Print.svg';

const runCompliance = (data, previousRoster) => {
    data.forEach(keyedRoster => {
        const employeeIds = keyedRoster?.getAllEmployeesId();
        employeeIds.forEach(employeeId => {
            if(!employeeId) return;
            let workItems = keyedRoster?.getWorkItemsForEmployee(employeeId).concat(previousRoster?.getWorkItemsForEmployee(employeeId) || []);
            if (workItems?.length) {
                const compliance = DriverCompliance.checkCompliance({employeeId, workItems});
                keys(compliance).forEach(workItemId => {
                    const workItem = find(workItems, wi => wi.workItemId === workItemId);
                    workItem.compliance = compliance[workItemId];
                    if (!workItem.compliance?.length) {
                        delete workItem.compliance;
                    }
                });
            }
        });
    });
};

export const getWorkItemColumns = ({
                                       table,
                                       workItemRenderer,
                                       selectedWorkItems,
                                       employeeData,
                                       scenario,
                                       setPrintWorkItems
                                   }) => {
    const startDate = scenario?.date
    return Array(7).fill().map((_, day) => ({
        title: <div>
            <div className='day-column'>
                {capitalize(dayjs().weekday(day).format('dddd'))}
                {startDate && <span>{dayjs(startDate).add(day, 'day').format('DD/MM/YYYY')}</span> }
            </div>
            {/*<Popover trigger={'click'} content={<div>{scenario.getAllEmployeesIdForDay(day).map(employeeId => {*/}
            {/*    const employee = employeeData.getEmployee(employeeId);*/}
            {/*    if (!employee) return null;*/}
            {/*    const totalTime = toHrsMinsSecs(scenario.getTotalTime('employee', employeeId));*/}
            {/*    const rosterTime = toHrsMinsSecs(scenario.getTotalTimeForDay('employee', employeeId, day));*/}
            {/*    const name = employeeName(employee) || 'Unknown';*/}
            {/*    const complianceForEmployee = [];*/}
            {/*    const workItemsForDay = scenario.getWorkItemsForEmployeeForDay(employeeId, day);*/}
            {/*    return (*/}
            {/*        <Flex key={table + '_' + day + '_employee_' + employeeId} className={`roster-employee`}>*/}
            {/*            <Tooltip overlayClassName="fixed-tooltip"*/}
            {/*                     title={<div>*/}
            {/*                         <div>{name} {rosterTime}</div>*/}
            {/*                         <div>{workItemsForDay?.length} work items</div>*/}
            {/*                         <div>Total: {totalTime}</div>*/}
            {/*                     </div>}*/}
            {/*                     placement="bottom">*/}
            {/*                <Avatar size={24}*/}
            {/*                        onClick={() => setPrintWorkItems(workItemsForDay)}*/}
            {/*                        style={{*/}
            {/*                            cursor: 'pointer',*/}
            {/*                            backgroundColor: complianceForEmployee?.length ? '#FFE6E6' : '#C1C5CE',*/}
            {/*                            color: complianceForEmployee?.length ? '#FF4D4D' : null,*/}
            {/*                            borderColor: complianceForEmployee?.length ? '#FDCCC9' : null*/}
            {/*                        }}>{createAcronym(name)}</Avatar>*/}
            {/*            </Tooltip>*/}
            {/*        </Flex>*/}
            {/*    );*/}
            {/*})}</div>}><Print*/}
            {/*    style={{width: 16, cursor: 'pointer'}}/></Popover>*/}
        </div>,
        dataIndex: table + '_' + dayjs().weekday(day).format('dddd'),
        render: (_, roster) => {
            if (!roster) return <div>Loading...</div>;
            return workItemRenderer(roster.workItems[day], roster, selectedWorkItems);
        },
    }));
};

function WeeklyRosterTable({
                               employeeData,
                               previousRoster,
                               allVehicles,
                               scenario, setScenario,
                               saveRoster,
                               newRoster,
                               selectedWorkItems,
                               setSelectedWorkItems,
                               allStops, allRoutes, allCharters, schedules, operator,
                               hideHeader, setPrintWorkItems
                           }) {
    const [showWorkDiary, setShowWorkDiary] = useState(false);


    const doSelectWorkItem = useCallback((workItem, _selectedWorkItems, roster) => {

        if (!workItem) {
            setSelectedWorkItems([]);
            return;
        }

        if (workItem?.optional) {
            workItem.prevRoster?.removeWorkItem(workItem);
            roster.saveOptionalWorkItem(workItem, scenario);
            saveRoster([roster, workItem.prevRoster]);
            setSelectedWorkItems(_selectedWorkItems.filter(wi => wi.workItemId !== workItem.workItemId));
        } else {
            if(selectedWorkItems?.find(wi => wi.workItemId === workItem?.workItemId)) {
                setSelectedWorkItems(selectedWorkItems.filter(wi => wi.workItemId !== workItem.workItemId));
            }else if (_selectedWorkItems?.find(wi => wi.workItemId === workItem?.workItemId)) {
                setSelectedWorkItems(_selectedWorkItems.filter(wi => wi.workItemId !== workItem.workItemId));
            } else {
                setSelectedWorkItems([..._selectedWorkItems, workItem]);
            }
        }
    }, [selectedWorkItems, setSelectedWorkItems, saveRoster, scenario]);

    const rosterData = useMemo(() => {
        let time = Date.now();
        const data = (scenario?.weeklyRosters || []).map(roster => {
            return WeeklyRoster.from({
                key: roster.rosterId,
                ...roster,
            });
        });

        if (operator?.opts?.compliance?.accept) {
            runCompliance(data, previousRoster);
        }
        console.log('WeeklyRoster data: ', Date.now() - time, 'ms');
        return data;
    }, [scenario?.weeklyRosters, previousRoster, operator]);

    const showDefaultMemberSeparately = (roster) => {
        const isDefaultUserInRoster = roster?.getEmployeeIds && roster.getEmployeeIds().includes(roster.defaultEmployeeId)
        return !isDefaultUserInRoster
    }

    const rosterRender = useCallback((roster) => {
        const time = Date.now();
        if (!roster) return <div>Loading...</div>;
        const d = <div key={'roster-row-' + roster.rosterId} span={3}
                       className="d-flex flex-row roster-cell-main" style={{gap: 6}}>
            <div className="roster-name">
                <div className="d-flex align-items-center justify-content-between">
                    <EditPopover title={'Edit roster name'}
                                 save={value => {
                                     roster.rosterName = value;
                                     saveRoster(roster);
                                 }}
                                 value={roster.rosterName}/>
                    <Button className={'icon-button btn-filled btn-error btn-delete'} icon={<DeleteOutlined/>}
                            onClick={() => {
                                setScenario(scenario => {
                                    scenario.removeRoster(roster);
                                    return scenario.clone();
                                });
                            }}/>
                </div>
                <div className="roster-time mt-1">{toHrsMinsSecs(roster.getTotalTime())}</div>
            </div>
            <div>
                {showDefaultMemberSeparately(roster) &&
                <div className={`roster-employee`}>
                    <AlternativeAllocationModal
                    roster={roster}
                    rosterEmployees={roster.getEmployeeIds()}
                    rosterData={rosterData}
                    operator={operator}
                    scenario={scenario}
                    isDefault={true}
                    employeeId={roster.defaultEmployeeId}
                    vehicleId={roster.defaultVehicleId}
                    employeeData={employeeData}
                    allVehicles={allVehicles}
                    allCharters={allCharters}
                    save={({employee, vehicle}) => {
                        roster.setDefaultEmployee(employee);
                        roster.setDefaultVehicle(vehicle);
                        saveRoster(roster);
                    }}
                    saveRoster={saveRoster}
                /></div>}
                <div className="d-flex flex-column" style={{gap: 8, paddingBottom: 8, marginBottom: 8, borderBottom: '1px solid #f0f0f0'}}>
                    {roster?.getEmployeeIds && roster.getEmployeeIds().map(employeeId => (
                        <div key={roster.rosterId + '_employee_' + employeeId} className={`roster-employee`}>
                            <AlternativeAllocationModal 
                                isDefaultAllocation={false}
                                rosterEmployees={roster.getEmployeeIds()}
                                roster={roster}
                                rosterData={rosterData}
                                operator={operator}
                                scenario={scenario}
                                employeeId={employeeId}
                                vehicleId={roster.getVehicleIds()[0]}
                                employeeData={employeeData}
                                allVehicles={allVehicles}
                                allCharters={allCharters}
                                save={({employee, vehicle}) => {
                                    const vehicleId = roster.getVehicleIds()[0];
                                    roster.replaceEmployee(employeeId, employee, scenario);
                                    roster.replaceVehicle(vehicleId, vehicle, scenario);
                                    saveRoster(roster);
                                }}
                                saveRoster={saveRoster}
                            />
                        </div>
                    ))}
                </div>

                <div className="d-flex flex-wrap" style={{gap: 6}}>
                    {roster?.getVehicleIds && roster.getVehicleIds().map(vehicleId => {
                        const vehicle = allVehicles[vehicleId];
                        const vehicleName = vehicle?.vehicleName || 'Unknown';
                        return (
                            <Tooltip className="vehicle-name" mouseEnterDelay={0.9}
                                    key={roster.rosterId + '_tt_vehicle_' + vehicleId}
                                    title={`${vehicleName} (${vehicle?.vehicleRego})`}
                                    placement="bottom">
                                {vehicleName}
                            </Tooltip>
                        );
                    })}
                </div>
            </div>
            {roster.containsOptional() ?
                <Tooltip title={`Allocate all options in roster ${roster.rosterName}`} mouseEnterDelay={0.9}>
                    <span className="icon-success"
                          onClick={e => {
                              e.stopPropagation();
                              e.preventDefault();
                              const optionalWorkItems = roster.getOptionalWorkItems().map(wi => wi.workItemId);
                              roster.saveOptionalWorkItems(scenario);
                              saveRoster([roster]);
                              setSelectedWorkItems(selectedWorkItems.filter(wi => !optionalWorkItems.includes(wi.workItemId)));
                          }}
                    ><Check/></span></Tooltip> : <></>}
        </div>;
        // console.log('WeeklyRosterTable rosterRender: ', Date.now() - time, 'ms');
        return d;
    }, [employeeData, allVehicles, scenario, setScenario, saveRoster, rosterData, operator,
        setSelectedWorkItems, selectedWorkItems]);

    const workItemRenderer = useCallback((workItems, roster, selectedWorkItems, statusColour = '#C1C5CE') => {
        const time = Date.now();
        const w = <WorkItemForDay scenario={scenario} roster={roster} workItems={workItems}
                                  selectedWorkItems={selectedWorkItems}
                                  onSelectWorkItem={doSelectWorkItem} statusColour={statusColour}
                                  employeeData={employeeData} allVehicles={allVehicles} saveRoster={saveRoster}
                                  allStops={allStops} allRoutes={allRoutes} allCharters={allCharters} schedules={schedules}
                                  operator={operator}
        />;
        // console.log('WeeklyRosterTable workItemRenderer: ', Date.now() - time, 'ms');
        return w;
    }, [doSelectWorkItem, scenario, employeeData, allVehicles, saveRoster]);

    // const workItemColumns = useMemo(() => {
    //     const time = Date.now();
    //     const wiColumns = Array(7).fill().map((_, day) => ({
    //         title: capitalize(dayjs().weekday(day).format('dddd')), dataIndex: day,
    //         render: (_, roster) => {
    //             if (!roster) return <div>Loading...</div>;
    //             return workItemRenderer(roster.workItems[day], roster, selectedWorkItems);
    //         }
    //     }));
    //     // console.log('WeeklyRosterTable workItemColumns: ', Date.now() - time, 'ms');
    //     return wiColumns;
    // }, [workItemRenderer, selectedWorkItems]);

    const columns = useMemo(() => {
        const time = Date.now();
        const columns = [{
            title: 'Roster',
            render: (_, roster) => rosterRender(roster)
        }].concat(getWorkItemColumns({
            table: 'rosters', workItemRenderer, selectedWorkItems, employeeData, scenario,
            setPrintWorkItems
        }));
        // console.log('WeeklyRosterTable columns: ', Date.now() - time, 'ms');
        return columns;
    }, [rosterRender, employeeData, scenario,
        allStops, allRoutes, schedules, showWorkDiary, setShowWorkDiary,
        workItemRenderer, selectedWorkItems]);

    return (<>
        <div className={'weekly-roster-table allocated-roster-table'}>
            <Table dataSource={rosterData} columns={columns}
                // expandable={{
                //     onExpand: (expanded, record) => {
                //         if (expanded) {
                //             setExpandedRosterIds([...expandedRosterIds, record.rosterId]);
                //         } else {
                //             setExpandedRosterIds(expandedRosterIds.filter(id => id !== record.rosterId));
                //         }
                //     },
                //     // expandedRowRender: (record) => (
                //     //     <p
                //     //         style={{
                //     //             margin: 0,
                //     //         }}
                //     //     >
                //     //         {record.description}
                //     //     </p>
                //     // ),
                //     expandRowByClick: true,
                // }}
                   pagination={false}
                //    virtual
                //    scroll={scroll || {
                //        y: 300,
                //    }}
                   locale={{
                       emptyText: <Empty description="No Data"><Button onClick={newRoster}>New roster</Button></Empty>
                   }}
                   onHeaderRow={() => hideHeader ? {style: {display: 'none'}} : {}}
            />
        </div>
    </>);
}

export default React.memo(WeeklyRosterTable);