import React, {memo, useCallback, useMemo, useState} from 'react';
import {Checkbox, Table} from 'antd';
import './Rosters.css';
import {WeeklyRoster} from '../../model/roster';
import {values} from 'lodash';
import {WorkItemForDay} from './WorkItemForDay';
import {getWorkItemColumns} from './WeeklyRosterTable';
// import weeklyRosters from './WeeklyRosters';

// const WorkItemView = memo(({workItem, selected, unallocated, onClick, statusColour}) => {
//     return <div
//         style={{cursor: 'pointer'}}
//         key={`work-item-div-${workItem.workItemId}`}
//         className={`roster-item ${unallocated ? 'item-unallocated' : ''} ${selected ? 'item-selected' : ''} ${workItem?.optional ? 'item-optional' : ''}`}
//         onClick={onClick}>
//         {!unallocated ?
//             <Allocation {...workItem}
//                         statusColour={statusColour}/> : <></>}
//         <div>
//             <div className="roster-name mb-1">{workItem.name}</div>
//             <div
//                 className="roster-time">{workItem.startTimeFormatted}-{workItem.endTimeFormatted}</div>
//         </div>
//     </div>;
// });

export const WeeklyUnallocatedTable = memo(({
                                                scenario,
                                                selectedWorkItems,
                                                setSelectedWorkItems,
                                                employeeData,
                                                schedules, allRoutes, allStops, allCharters,
                                                setPrintWorkItems
                                            }) => {
    const [showWorkDiary, setShowWorkDiary] = useState(false);

    const onSelectWorkItem = useCallback((workItem, _selectedWorkItems) => {
        if (!workItem) {
            setSelectedWorkItems([]);
            return;
        }
        if(selectedWorkItems?.find(wi => wi.workItemId === workItem?.workItemId)) {
            setSelectedWorkItems(selectedWorkItems.filter(wi => wi.workItemId !== workItem.workItemId));
        } else if (_selectedWorkItems?.find(wi => wi.workItemId === workItem?.workItemId)) {
            setSelectedWorkItems(_selectedWorkItems.filter(wi => wi.workItemId !== workItem.workItemId));
        } else {
            setSelectedWorkItems([..._selectedWorkItems, workItem]);
        }
    }, [selectedWorkItems, setSelectedWorkItems]);

    const workItemRenderer = useCallback((workItems, roster, selectedWorkItems) => {
        return <WorkItemForDay roster={roster} workItems={workItems} selectedWorkItems={selectedWorkItems}
                               schedules={schedules} allRoutes={allRoutes} allStops={allStops} allCharters={allCharters}
                               onSelectWorkItem={onSelectWorkItem} unallocated={true}/>;
    }, [onSelectWorkItem]);

    const columns = useMemo(() => {
        return [{
            title: 'Roster',
            dataIndex: 'rosterName',
            render: (_, roster) => {
                let allSelected = roster.workItems.flat().every(wi => selectedWorkItems.find(selectedWi => selectedWi.workItemId === wi.workItemId));
                return <div className="roster-cell-main d-flex justify-content-between">
                    <div className="roster-name">{roster.rosterName}</div>
                    <Checkbox
                        indeterminate={!allSelected && roster.workItems.flat().some(wi => selectedWorkItems.find(selectedWi => selectedWi.workItemId === wi.workItemId))}
                        checked={allSelected}
                        onChange={e => {
                            setSelectedWorkItems(e.target.checked ? roster.workItems.flat() : []);
                        }}
                    />
                </div>;
            }
        }].concat(getWorkItemColumns({
            table: 'unallocated', workItemRenderer, selectedWorkItems, employeeData, scenario,
            setPrintWorkItems
        }));

    }, [employeeData, scenario,
        allStops, allRoutes, schedules, showWorkDiary, setShowWorkDiary,
        workItemRenderer, selectedWorkItems]);

    const unallocatedRosters = useMemo(() => {
        let time = Date.now();
        const weeklyRosters = {};
        scenario.unallocatedRoster?.workItems.forEach((workItemsForDay, day) => {
            workItemsForDay
                .forEach(workItem => {
                    const rosterId = workItem.getDuty().shiftBatId;
                    let roster = weeklyRosters[rosterId];
                    if (!roster) {
                        roster = WeeklyRoster.from({
                            rosterId,
                            key: rosterId,
                            startTime: workItem.getDuty().getStartTime(),
                            rosterName: workItem.getDuty().shiftBatNumber
                        });
                        weeklyRosters[rosterId] = roster;
                    }
                    const isAlreadyAllocated = scenario.isWorkItemAlreadyAllocated(workItem);
                    if(!isAlreadyAllocated) 
                        roster.workItems[day].push(workItem);
                });
        });

        console.log('WeeklyRoster unallocated data: ', Date.now() - time, 'ms');
        return values(weeklyRosters).filter(roster => {
            return roster.workItems.flat().length > 0; // filter out empty unallocated rosters
        }).sort((a, b) => a.startTime - b.startTime);
    }, [scenario?.unallocatedRoster?.workItems]);

    return (<>{unallocatedRosters?.length ?
        <div className={'weekly-roster-table unallocated-roster-table'}>
            <Table dataSource={unallocatedRosters} columns={columns}
                // expandable={{
                //     onExpand: (expanded, record) => {
                //         if (expanded) {
                //             setExpandedRosterIds([...expandedRosterIds, record.rosterId]);
                //         } else {
                //             setExpandedRosterIds(expandedRosterIds.filter(id => id !== record.rosterId));
                //         }
                //     },
                //     // expandedRowRender: (record) => (
                //     //     <p
                //     //         style={{
                //     //             margin: 0,
                //     //         }}
                //     //     >
                //     //         {record.description}
                //     //     </p>
                //     // ),
                //     expandRowByClick: true,
                // }}
                   pagination={false}
                //    virtual
                //    scroll={scroll}
                   rowClassName={() => 'unallocated-roster'}
                   sticky={true}
            />
        </div> : <></>}
    </>);
});

