import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import DeckGL from '@deck.gl/react';
import {_MapContext, FlyToInterpolator, Marker, NavigationControl, StaticMap, WebMercatorViewport} from 'react-map-gl';
import {GeoJsonLayer} from '@deck.gl/layers';
import config from '../config';
import {
    getViewport,
    getViewportForStops,
    toGeoJsonStopCollection,
    toGeoJsonWithPts,
    toGeoJsonWps,
    toPublicGeoJson,
    toSegmentedGeoJson,
    waypointsFromPolyline
} from '../libs/mapLib';
import Pin from './Pin';
import {getBearingAtCoordinate, getDistanceInMetres, getNearestSegment, getNearestWaypoint,} from '../libs/routes-lib';
import StopInfo from './StopInfo';
import './RouteMap.css';
import ControlPanel from './ControlPanel';
import {hexToRgb, toKmMs} from '../libs/formatLib';
import {PathStyleExtension} from '@deck.gl/extensions';
import LoadMessage from '../components/LoadMessage';
import mbxMapMatching from '@mapbox/mapbox-sdk/services/map-matching';
import mbxClient from '@mapbox/mapbox-sdk';
import {chunk, cloneDeep, find, findIndex, flatten, last, values} from 'lodash';
import {debounce} from 'lodash/function';
import {LngLatBounds} from 'mapbox-gl';
import util from 'util';
import MapLayerToolbar from './MapLayerToolbar';
import MapToolbar from './MapToolbar';
import {isPointInPolygon} from 'geolib';
import {message} from 'antd';
import {fetchMasterStopsInBounds} from '../services/routeService';
import {useAppContext} from '../libs/contextLib';
import {noop} from 'lodash/util';
import MapEditorToolbar from './MapEditorToolbar';
import MapWpSelectorToolbar from './MapWpSelectorToolbar';
import {BusRoute} from '../model/busRoute';
import {Tooltip} from 'antd/lib';
import {findDivergenceByDistance, getCachedPathFromCoordsDebounced, getCachedPathMapbox} from '../libs/pathLib';
import log from 'loglevel';
import {intersectionBy} from 'lodash/array';
import {ReactComponent as TransferPin} from '../assets/icons/transfer-pin.svg';
import {ReactComponent as Chat} from '../assets/icons/Chat.svg';
import {MAPBOX_STYLES} from './StopsMapViewer';
import {ulid} from 'ulid';
import {stopModelData} from '../services/ModelService';
import {ReactComponent as Services} from '../assets/icons/Services.svg';
import {localGeocoder, renderSuggestions} from '../libs/geocoderLib';
import {Button, Image} from 'react-bootstrap';
import Geocoder from 'react-map-gl-geocoder';
import MapPopup from './MapPopup';
import {RouteChat} from './ChatComponent';
import {ChatContext} from '../model/Comment';
import useAllComments from '../hooks/useAllComments';
import {whilst} from 'async';

const MAPBOX_ACCESS_TOKEN = config.maps.mabBox;

const baseClient = mbxClient({accessToken: MAPBOX_ACCESS_TOKEN});
const mapMatchingService = mbxMapMatching(baseClient);

const navStyle = {
    position: 'absolute', top: 0, left: 0, padding: '10px'
};

const preloadImgData = Array(360).fill(0).map((_, i) => {
    return `url('data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path transform='rotate(${i}, 256, 256)' d='M504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256zm72 20v-40c0-6.6 5.4-12 12-12h116v-67c0-10.7 12.9-16 20.5-8.5l99 99c4.7 4.7 4.7 12.3 0 17l-99 99c-7.6 7.6-20.5 2.2-20.5-8.5v-67H140c-6.6 0-12-5.4-12-12z'/></svg>')`;
});


// const BASE_TOLERANCE = 0.0000001

const DEFAULT_VIEWSTATE = {
    width: 798,
    height: 610,
    latitude: -26.089768592081594,
    longitude: 136.253744512621,
    zoom: 3,
    bearing: 0,
    pitch: 0,
    altitude: 1.5,
    maxZoom: 20,
    minZoom: 0,
    maxPitch: 60,
    minPitch: 0
};

const logger = log.getLogger('ServiceDetails');

export default function RouteMap({
                                     isLoading,
                                     route,
                                     className = '',
                                     tolerance,
                                     setTolerance = noop,
                                     updateRouteFn,
                                     createNewStop,
                                     deleteStop,
                                     hideContextMenu,
                                     colour,
                                     startTime,
                                     handleCut,
                                     selectedTripId,
                                     onHandleStopClick,
                                     updatedTrips,
                                     selectedStop,
                                     setSelectedStop = noop,
                                     updateRoutePathFn,
                                     handleTrim,
                                     allStops,
                                     setAllStops,
                                     focusStop,
                                     setFocusStop,
                                     deselectStop,
                                     immutable,
                                     zoomStop,
                                     setZoomStop,
                                     showAllStops,
                                     setShowAllStops,
                                     editingRoute,
                                     setEditingRoute,
                                     allowComments = false,
                                     showComments,
                                     setShowComments,
                                     charter,
                                     ...props
                                 }) {
    const {editor} = useAppContext();
    const [viewState, setViewState] = useState(DEFAULT_VIEWSTATE);
    // const [route, setRoute] = useState(null);
    // const [route, setRoute] = useState({routeNumber: '', routeName: '', routeDetails: '', waypoints: [], stops: []});
    // const [colour, setColour] = useState(props.colour);
    const [viewFeatures, setViewFeatures] = useState(null);
    const [features, setFeatures] = useState(null);
    // const [selectedStop, setSelectedStop] = useState(props.selectedStop);
    const [markers, setMarkers] = useState([]);
    const [stopsInView, setStopsInView] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [selectedMarkers, setSelectedMarkers] = useState([]);
    const [selectedPt, setSelectedPt] = useState(null);
    const [focusMarker, setFocusMarker] = useState(null);
    // const [editing, setEditing] = useState(false);
    // const [baseWaypoints, setBaseWaypoints] = useState([])
    const [snapDistance, setSnapDistance] = useState(15);

    const wpSelectorRoutes = useMemo(() => [route], [route]);

    const editLayer = useRef(null);
    const navLayer = useRef(null);
    const viewingLayer = useRef(null);
    const allStopsLayer = useRef(null);
    const masterStopsLayer = useRef(null);
    const guideLayer = useRef(null);
    const transfersLayers = useRef([]);
    const [layers, setLayers] = useState({
        viewing: viewingLayer,
        nav: navLayer,
        edit: editLayer,
        allStops: allStopsLayer,
        masterStops: masterStopsLayer,
        line: guideLayer,
        transfers: transfersLayers
    });
    const [selectedStartWpIdx, setSelectedStartWpIdx] = useState(-1);
    const [selectedEndWpIdx, setSelectedEndWpIdx] = useState(-1);
    const [wpIdxsInView, setWpIdxsInView] = useState([]);
    const [bounds, setBounds] = useState(null);
    const setBoundsDebounced = debounce(bounds => setBounds(bounds), 2000);

    const [isExtending, setIsExtending] = useState(false);
    const [isSnapping, setIsSnapping] = useState(false);

    const [style, setStyle] = useState('light');

    const [routeBuilding, setRouteBuilding] = useState(false);
    const [mouseLocation, setMouseLocation] = useState(null);

    const [showCreateStop, setShowCreateStop] = useState(false);
    const [guidePath, setGuidePath] = useState(null);

    const [messageApi, contextHolder] = message.useMessage();
    const [masterStops, setMasterStops] = useState([]);
    const [fetchMasterStopsFn, setFetchMasterStopsFn] = useState(null);

    const mapRef = useRef(null);
    const [mapController] = useState({
        scrollZoom: true, dragPan: true, doubleClickZoom: false
    });
    const [transferMarker, setTransferMarker] = useState(null);

    const routeMapRef = useRef(null);
    const [listening, setListening] = useState(false);

    const [loadingmasterStops, setLoadingmasterStops] = useState(false);
    const geocoderContainerRef = useRef(null);
    const geocoderInputRef = useRef(null);
    const [poiMarker, setPoiMarker] = useState(null);
    const [geocoder, setGeocoder] = useState(false);
    const [popupData, setPopupData] = useState(null);

    const [showNavigation, setShowNavigation] = useState(false);
    const [calculatingCheckpoints, setCalculatingCheckpoints] = useState(false);

    const {allRouteComments} = useAllComments();
    const [chatContext, setChatContext] = useState(null);
    const [selectedChatContext, setSelectedChatContext] = useState(null);

    let singleWpSelected = useCallback((idx = null) => {
        if (Number.isFinite(idx)) {
            setSelectedStartWpIdx(idx);
            setSelectedEndWpIdx(idx);
            return true;
        }
        return selectedStartWpIdx > -1 && selectedEndWpIdx === selectedStartWpIdx && selectedStartWpIdx < route.waypoints.length;
    }, [selectedStartWpIdx, selectedEndWpIdx, setSelectedStartWpIdx, setSelectedEndWpIdx, route.waypoints]);

    const wpsSelected = useCallback((forceMore = false) => selectedStartWpIdx > -1 && (forceMore ? selectedEndWpIdx > selectedStartWpIdx : selectedEndWpIdx >= selectedStartWpIdx), [selectedStartWpIdx, selectedEndWpIdx]);

    const canEnableBuild = useCallback(() => {
        return selectedMarker || selectedPt || (singleWpSelected() && selectedEndWpIdx === route.waypoints.length - 1) || wpsSelected(true);
    }, [selectedMarker, selectedPt, singleWpSelected, selectedStartWpIdx, selectedEndWpIdx, wpsSelected]);

    let handleStopClick = useCallback((stop) => {
        if (!stop) {
            return;
        }
        stop.nearestSegment = getNearestSegment(stop, route.waypoints, {});
        if (routeBuilding) {
            if (selectedMarker) {
                if (stop.nearestSegment?.segment?.startIdx === 0 && route.stops.every(s => selectedMarker.stopId !== s.stopId)) {
                    addSelectedStop(route, selectedMarker, 'prepend').then(() => {
                        setSelectedMarker(null);
                        singleWpSelected(-1);
                        setRouteBuilding(false);
                    });
                } else {
                    addSelectedStop(route, stop, 'append').then(() => {
                        singleWpSelected(-1);
                    });
                }
            } else if (singleWpSelected()) {
                // Could be in the middle of the route
                const wpCount = route.waypoints.length;
                appendPathToPt(stop, 'append', selectedStartWpIdx).then(() => {
                    addSelectedStop(route, stop).then(() => {
                        setSelectedMarker(null);
                        singleWpSelected(selectedStartWpIdx === -1 ? -1 : selectedStartWpIdx + route.waypoints.length - wpCount);
                    });
                });
            } else if (selectedPt && route.stops[0].stopId === stop.stopId) {
                // Only prepend to path if the clicked stop is the first stop in the route
                appendPathToPt(selectedPt, 'prepend').then(() => {
                    setSelectedPt(null);
                    setRouteBuilding(false);
                    setSelectedMarker(null);
                    setSelectedMarkers([]);
                    setSelectedStop(null);
                });


            }
        }
        setSelectedStop(stop);
        setSelectedPt(null);
        setFocusMarker(null);
        setZoomStop(null);
        // eslint-disable-next-line
    }, [setSelectedStartWpIdx, setSelectedEndWpIdx, route, setSelectedStop, routeBuilding, singleWpSelected, selectedPt, selectedMarker, setZoomStop]);

    useEffect(() => {
        if (!showComments) {
            setPoiMarker(null);
        }
    }, [showComments, setPoiMarker]);

    useEffect(() => {
        if (selectedStop && selectedStop !== selectedMarker && route?.waypoints) {
            console.log('Setting selected stop');
            // setSelectedMarkers(selectedMarkers => uniqBy([...selectedMarkers, ...markers.filter(m => selectedStop.stopId === m.stopId)], 'stopTimeId'));

            const selectedMarkers = [{
                ...selectedStop,
                sequence: route.stopTimes.findIndex(st => st.stopTimeId === selectedStop.stopTimeId) + 1,
            }];

            markers.filter(marker => marker.stopTimeId !== selectedStop.stopTimeId)
                .forEach(marker => {
                    if (getDistanceInMetres(selectedStop, marker) < 50) {
                        selectedMarkers.push(marker);
                    }
                });
            setSelectedMarkers(selectedMarkers);
            // setSelectedMarkers([selectedStop]);
            setSelectedMarker(selectedStop);
        }
        // eslint-disable-next-line
    }, [setSelectedMarkers, setSelectedMarker, route, markers, setViewState, allStops, selectedStop]);

    useEffect(() => {
        if (zoomStop && zoomStop !== selectedMarker && route?.waypoints) {
            setViewState(viewState => {
                if (viewState) {
                    const stopToPan = allStops[zoomStop.stopId];
                    if (stopToPan && Number.isFinite(stopToPan.lat) && Number.isFinite(stopToPan.lon)) {
                        return {
                            ...viewState,
                            zoom: 14,
                            latitude: stopToPan.lat,
                            longitude: stopToPan.lon,
                            transitionInterpolator: new FlyToInterpolator(),
                            transitionDuration: 500
                        };
                    }
                }
                return getViewport({geos: route, padding: 0.02, viewport: viewState});
            });
            setZoomStop(null);
        }
        // eslint-disable-next-line
    }, [setSelectedMarker, route, markers, setViewState, allStops, zoomStop, setZoomStop]);

    useEffect(() => {
        if (focusStop) {
            setFocusMarker(focusStop);
        }
    }, [focusStop, setFocusMarker]);

    useEffect(() => {
        if ((props.focusTransfers?.to?.length || props.focusTransfers?.from?.length)) {
            setTransferMarker({
                ...props.focusTransfers.stop,
                transfersTo: props.focusTransfers.to,
                transfersFrom: props.focusTransfers.from
            });
        } else {
            setTransferMarker(null);
        }
    }, [props.focusTransfers, setTransferMarker]);

    const updateRoute = useCallback((route, calculateStartEnd = true) => {
        console.log('Finish move');
        updateRouteFn(route, calculateStartEnd);
        console.log('WP deltas after update waypoint', route.waypoints.map(wp => ({...wp})));
    }, [updateRouteFn]);

    const createWaypoint = useCallback((positionIndex = route.waypoints.length, latitude, longitude) => {
        // Get point at positionIndex
        const prevWp = route.waypoints[positionIndex - 1];
        if (!prevWp) {
            return route.waypoints.push({
                lat: latitude, lon: longitude, delta: 0, distance: 0, avgSpd: 0
            });
        }
        // use avgSpd and distance from to get delta
        const distanceToPrevWp = getDistanceInMetres(prevWp, {lat: latitude, lon: longitude});
        const newDelta = distanceToPrevWp / route.getAvgSpeed();

        // create wp and route.stops.splice(positionIndex, 0, wp);
        route.waypoints.splice(positionIndex, 0, {
            lat: latitude,
            lon: longitude,
            delta: prevWp.delta + newDelta,
            distance: prevWp.distance + distanceToPrevWp,
            avgSpd: prevWp.avgSpd
        });

        console.log('WP deltas after new waypoint', route.waypoints.map(wp => ({...wp})));

        updateRoute(route);
    }, [route, updateRoute]);


    // eslint-disable-next-line
    const updateRouteDebounced = useCallback(debounce((r) => {
        updateRouteFn(r);
    }, 2000), [updateRouteFn]);

    useEffect(() => {
        if (!editingRoute && selectedStartWpIdx > -1 && selectedEndWpIdx >= selectedStartWpIdx) {
            const fc = toSegmentedGeoJson(route);
            fc.features = fc.features.concat(toGeoJsonWps(route, route.waypoints.slice(selectedStartWpIdx, selectedEndWpIdx + 1)));
            setViewFeatures(fc);
        } else {
            setViewFeatures(toSegmentedGeoJson(route));
        }
    }, [route, editingRoute, setViewFeatures, selectedStartWpIdx, selectedEndWpIdx]);

    useEffect(() => {
        if (route) {
            viewingLayer.current = new GeoJsonLayer({
                id: `route-layer-${route.routeId}`,
                data: viewFeatures,
                radiusScale: 5,
                pointRadiusMinPixels: 8,
                pointRadiusMaxPixels: 12,
                lineWidthMinPixels: 2,
                lineWidthMaxPixels: 8,
                pickable: true, // props added by PathStyleExtension
                getDashArray: (feature) => {
                    if (feature.id === 'public') {
                        return [0, 0];
                    }
                    return [4, 3];
                },
                getLineColor: (feature) => {
                    if (feature?.geometry?.type === 'Point') {
                        return [255, 255, 255];
                    }
                    return hexToRgb(route.colour);
                },
                getFillColor: [255, 0, 0],
                getPointRadius: 8,
                pointRadiusUnits: 'pixels',
                dashJustified: false,
                extensions: [new PathStyleExtension({highPrecisionDash: true})],
                visible: !editingRoute
            });

            if (showNavigation && route.stops?.length > 2) {

                // getCachedPathFromCoordsDebounced('bus', route.waypoints).then(path => {
                //     console.log(path)
                //     navLayer.current = new GeoJsonLayer({
                //         id: `route-layer-${route.routeId}-nav`,
                //         data: path.geojson,
                //         radiusScale: 5,
                //         lineWidthMinPixels: 2,
                //         lineWidthMaxPixels: 8,
                //         pickable: true, // props added by PathStyleExtension
                //         getDashArray: (feature) => {
                //             if (feature.id === 'public') {
                //                 return [0, 0];
                //             }
                //             return [2, 1];
                //         },
                //         getLineColor: (feature) => {
                //             return [255, 200, 99];
                //         },
                //         getLineWidth: () => 50,
                //         dashJustified: false,
                //         extensions: [new PathStyleExtension({highPrecisionDash: true})],
                //     });
                //     setLayers(layers => ({...layers}));
                // })


                const allCheckPoints = route.waypoints.filter(wp => wp.stopTimeId || wp.checkpoint);
                const features = [];
                let wpIdx = 0;
                const result = [];
                let currentGroup = [];

                allCheckPoints.forEach((wp) => {
                    if (wp.stopTimeId) {
                        if (currentGroup.length > 0) {
                            currentGroup.push(wp);
                            result.push(currentGroup);
                        }
                        currentGroup = [wp]; // Start a new group with the current stopTime
                    } else {
                        currentGroup.push(wp); // Add checkpoints in between
                    }
                });

                // Push the final group if it exists
                if (currentGroup.length > 1) {
                    result.push(currentGroup);
                }


                whilst(
                    cb => {
                        cb(null, wpIdx <= result.length - 1);
                    },
                    cb => {
                        getCachedPathMapbox('driving', result[wpIdx++]).then(path => {
                            features.push(path.geojson);
                            cb();
                        });
                    }).then(() => {
                    navLayer.current = new GeoJsonLayer({
                        id: `route-layer-${route.routeId}-nav`,
                        data: {type: 'FeatureCollection', features},
                        radiusScale: 5,
                        lineWidthMinPixels: 2,
                        lineWidthMaxPixels: 8,
                        pickable: true, // props added by PathStyleExtension
                        getDashArray: (feature) => {
                            if (feature.id === 'public') {
                                return [0, 0];
                            }
                            return [2, 1];
                        },
                        getLineColor: (feature) => {
                            return [255, 200, 99];
                        },
                        getLineWidth: () => 50,
                        dashJustified: false,
                        extensions: [new PathStyleExtension({highPrecisionDash: true})],
                    });
                    setLayers(layers => ({...layers}));
                });
            } else {
                navLayer.current = null;
            }

            const transLayers = [];

            Object.keys(route.services).forEach(tripId => {
                route.services[tripId].stopTimes.forEach(st => {
                    if (st.transfersTo?.length) {
                        intersectionBy(props.focusTransfers?.to, st.transfersTo, 'routeId').forEach(tx => {
                            const txRoute = props.allRoutes[tx.routeId];
                            if (!txRoute) return;
                            txRoute.calculateStartEnd({firstStop: st});
                            let selectedPath = toPublicGeoJson(txRoute);
                            let layerProps = {
                                id: `tx-to-route-layer-${txRoute.routeId}`,
                                data: selectedPath,
                                lineWidthMinPixels: 5,
                                getLineColor: () => {
                                    return hexToRgb(txRoute.colour);
                                },
                                pickable: true,
                            };
                            transLayers.push(new GeoJsonLayer(layerProps));
                        });
                    }
                    if (st.transfersFrom?.length) {
                        intersectionBy(props.focusTransfers?.from, st.transfersFrom, 'routeId').forEach(tx => {
                            const txRoute = props.allRoutes[tx.routeId];
                            if (!txRoute) return;
                            txRoute.calculateStartEnd({lastStop: st});
                            let selectedPath = toPublicGeoJson(txRoute);
                            let layerProps = {
                                id: `tx-from-route-layer-${txRoute.routeId}`,
                                data: selectedPath,
                                lineWidthMinPixels: 5,
                                getLineColor: () => {
                                    return hexToRgb(txRoute.colour);
                                },
                                pickable: true,
                            };
                            transLayers.push(new GeoJsonLayer(layerProps));
                        });
                    }
                });
            });
            transfersLayers.current = transLayers;

            setLayers(layers => ({...layers}));

            if (allowComments && showComments) {
                if (props.focusPt) {
                    setViewState(viewState => {
                        if (Number.isFinite(props.focusPt.lat) && Number.isFinite(props.focusPt.lon)) {
                            return {
                                ...viewState,
                                zoom: 16,
                                latitude: props.focusPt.lat,
                                longitude: props.focusPt.lon,
                                transitionInterpolator: new FlyToInterpolator(),
                                transitionDuration: 500
                            };
                        }
                        return {...viewState};
                    });
                    setPoiMarker(props.focusPt);
                    props.setFocusPt(null);

                }
            }
        }
    }, [colour, route, editingRoute, viewFeatures, setLayers, props.focusTransfers, props.allRoutes, allowComments,
        showComments, setPoiMarker, props.setFocusPt, props.focusPt, showNavigation]);


    const onMarkerDragEnd = (event, stopId) => {
        let draggedMarker = markers.filter(stop => stop.stopId === stopId)[0];
        draggedMarker.lat = event.lngLat[1];
        draggedMarker.lon = event.lngLat[0];
        draggedMarker.dirty = true;
        setMarkers([...markers]);
        route.stops = markers;
        route.calculateStartEnd({});
        updateRoute(route, false);
        // setViewFeatures(toSegmentedGeoJson(route));
        // setFeatures(toGeoJson(route));
        setSelectedMarker({...draggedMarker, editing: true});
    };

    const cancelDebounceFetchMasterStops = useCallback(() => {
        if (fetchMasterStopsFn?.cancel) {
            fetchMasterStopsFn.cancel();
        }
        setLoadingmasterStops(false);
    }, [fetchMasterStopsFn, setLoadingmasterStops]);

    // eslint-disable-next-line
    // const updatemasterStops = useCallback((viewState) => {
    //     if (viewState.zoom < 12 || !showAllStops) {
    //         console.log('Zoom in to see source system points: ', viewState.zoom)
    //         return
    //     }
    //     setLoadingmasterStops(true);
    //
    //     let minLat = Math.min(...bounds.map((p) => p[1]));
    //     let minLon = Math.min(...bounds.map((p) => p[0]));
    //     let maxLat = Math.max(...bounds.map((p) => p[1]));
    //     let maxLon = Math.max(...bounds.map((p) => p[0]));
    //
    //     console.log(minLat, minLon, maxLat, maxLon)
    //
    //     if (showAllStops) {
    //         fetchMasterStopsInBounds({
    //             bounds: {
    //                 minLat,
    //                 minLon,
    //                 maxLat,
    //                 maxLon
    //             }, excluding: stopsInView, types: ['bus']
    //         }).then(masterStops => {
    //             if (showAllStops) {
    //                 masterStopsLayer.current = getPickableStopsLayer({
    //                     id: 'master-stops-layer',
    //                     pts: masterStops,
    //                     route,
    //                     handleStopClick,
    //                     setSelectedPt,
    //                     wpsSelected,
    //                     guidePath
    //                 });
    //                 setLayers({...layers})
    //             }
    //             setLoadingmasterStops(false);
    //         });
    //     } else {
    //         masterStopsLayer.current = null;
    //     }
    //     setLayers({...layers})
    //
    // }, [setLayers, operator, showAllStops, setLoadingmasterStops, stopsInView, route, handleStopClick,
    //     setSelectedPt, wpsSelected, guidePath, setFetchMasterStopsFn, bounds]);

    // useEffect(() => {
    //     if (showAllStops) {
    //         updatemasterStops(viewState)
    //         console.log('source points loaded.')
    //     } else {
    //         masterStopsLayer.current = null;
    //     }
    //     setLayers({...layers})
    // }, [showAllStops, updatemasterStops, viewState, setLayers])

    const appendPathToPt = useCallback(async (selectedPt, method = 'append', startIdx, endIdx, keepSelectedPt = false) => {
        if (selectedPt) {
            setIsExtending(true);

            try {
                if (method === 'prepend') {
                    if (!(await route.prependPath(selectedPt))) {
                        setIsExtending(false);
                        return messageApi.error('Could not extend path to selected pt.', 5);
                    }
                } else {
                    if (!(await route.appendPath(selectedPt, startIdx, endIdx))) {
                        setIsExtending(false);
                        return messageApi.error('Could not extend path to selected pt.', 5);
                    }
                }
                // route.calculateStartEnd({});
                setSelectedMarkers([]);
                setSelectedMarker(null);
                if (!keepSelectedPt) {
                    setSelectedPt(null);
                }
                updateRoute(route);
            } catch (e) {
                messageApi.error('Could not extend path to selected pt. Error: ' + e.toString(), 5);
            }
            setIsExtending(false);
        }
    }, [route, setSelectedMarker, setSelectedMarkers, setSelectedPt, updateRoute, messageApi]);

    const insertPathBetweenPt = useCallback(async (selectedPt, startWpIdx, endWpIdx) => {
        if (selectedPt && startWpIdx !== endWpIdx) {
            setIsExtending(true);

            try {
                if (!(await route.insertPath(selectedPt, startWpIdx, endWpIdx))) {
                    setIsExtending(false);
                    return messageApi.error('Could not insert path to selected pt.');
                }
                // route.calculateStartEnd({});
                setSelectedMarkers([]);
                setSelectedMarker(null);
                setSelectedPt(null);
                setSelectedStartWpIdx(0);
                setSelectedEndWpIdx(0);
                updateRoute(route);
            } catch (e) {
                console.log(e, e);
                messageApi.error('Could not insert path to selected pt. Error: ' + e.toString(), 5);
            }
            setIsExtending(false);
        }
    }, [route, setSelectedMarker, setSelectedMarkers, setSelectedPt, setSelectedStartWpIdx, setSelectedEndWpIdx, updateRoute, messageApi]);

    const addSelectedStop = useCallback(async (route, selectedMarker, method = 'add', selectWp = false, buildToStop = false) => {
        if (route && selectedMarker) {
            console.log('Adding stop: ', selectedMarker);
            setIsExtending(true);

            let master = false;
            if (selectedMarker.master) {
                master = true;
                selectedMarker = {...selectedMarker, stopId: ulid(), verified: 1, duplicate: -1, outOfSync: []};
                delete selectedMarker.master;
                await stopModelData.save(selectedMarker);
                setAllStops(allStops => ({...allStops, [selectedMarker.stopId]: selectedMarker}));
                console.log('Master stop added: ', selectedMarker);
            }
            try {
                if (!(await route.addStop({...selectedMarker}, method, !!charter))) {
                    if (!buildToStop) {
                        setIsExtending(false);
                        return messageApi.error('Could not ' + method + ' stop. It may be too far away from the route.', 5);
                    }

                    if (!(await route.appendStop(selectedMarker))) {
                        setIsExtending(false);
                        return messageApi.error('Could not extend path to selected pt.', 5);
                    }
                }
                // allStops[selectedMarker.stopId] = selectedMarker;
                // setAllStops({...allStops})
                // route.calculateStartEnd({});
                // const markers = selectedTrip?.stopTimes.map((stop, idx) => {
                //     return {...stop, ...allStops[stop.stopId], sequence: idx + 1, key: idx}
                // }) || [];
                // setMarkers(markers);
                // setSelectedMarkers([]);
                // setSelectedStop(null);
                updateRoute(route);
                // setViewFeatures(toSegmentedGeoJson(route));
                // setRaoute({...route});
                // if (master) {
                //     await createNewStop(selectedMarker);
                // }
            } catch (e) {
                console.log(e, e);
                messageApi.error('Could not ' + method + ' stop. Error: ' + e.toString(), 5);
            }
            setIsExtending(false);
        }
    }, [setSelectedMarkers, updateRoute, setMarkers, selectedTripId, setSelectedStop, messageApi]);

    const handleBuildRoute = useCallback(async (method = 'append', keepSelectedPt) => {
        if (selectedMarker) {
            await addSelectedStop(route, selectedMarker, selectedStartWpIdx > selectedEndWpIdx ? 'insert' : method);
            setSelectedPt(null);
        } else if (selectedPt) {
            if (selectedStartWpIdx > selectedEndWpIdx) {
                await insertPathBetweenPt(selectedPt, selectedStartWpIdx, selectedEndWpIdx);
            } else {
                await appendPathToPt(selectedPt, method, null, null, keepSelectedPt);
            }
            setSelectedMarker(null);
        }
    }, [route, selectedMarker, addSelectedStop, selectedStartWpIdx, selectedEndWpIdx, insertPathBetweenPt, appendPathToPt, selectedPt]);

    const deleteSelectedStop = useCallback((selectedMarker) => {

        if (!window.confirm(`Are you sure you want to remove the stop time ${selectedMarker.stopName}?`)) {
            return;
        }

        if (selectedMarker) {
            route.removeStop(selectedMarker);
            // setMarkers([...route.stops]);
            // route.calculateStartEnd({});
            setSelectedMarkers([]);
            setSelectedMarker(null);
            setSelectedStop(null);
            updateRoute(route);
            deleteStop(selectedMarker);
            // setViewFeatures(toSegmentedGeoJson(route));
        }
    }, [route, setSelectedMarker, setSelectedMarkers, updateRoute, deleteStop, setSelectedStop]);

    const handlePickableStopClick = useCallback((pickInfo, e) => {
        if (e.srcEvent?.target?.className?.includes('mapboxgl-ctrl-geocoder')) {
            return;
        }
        const stop = pickInfo.object?.stop;

        if (routeBuilding && stop && wpsSelected() && !stop.selectedPt && guidePath?.waypoints?.length) {
            route.replacePath(guidePath, selectedStartWpIdx, selectedEndWpIdx).then(() => {
                addSelectedStop(route, stop).then(() => {
                    singleWpSelected(-1);
                    setSelectedPt(null);
                    setSelectedMarker(null);
                    setSelectedStop(null);
                    setSelectedMarkers([]);
                    setRouteBuilding(false);
                    updateRoute(route);
                });
            });
            return;
        }

        if (stop && !stop.selectedPt) {
            handleStopClick(stop);
        }
        if (stop?.selectedPt) {
            console.log('Removing selected Pt.');
            setSelectedPt(null);
        }
    }, [guidePath, handleStopClick, setSelectedPt, routeBuilding, wpsSelected, route, addSelectedStop, singleWpSelected, setSelectedMarker, setSelectedStop, setSelectedMarkers, updateRoute]);

    const getPickableStopsLayer = useCallback(({id, pts}) => {
        return new GeoJsonLayer({
            id,
            data: toGeoJsonStopCollection(pts, {offsetSameStops: true}), // selectedFeatureIndexes: selectedIdx >= 0 ? [selectedIdx] : [],
            pickingRadius: 10,
            stroked: true,
            filled: true,
            radiusScale: 5,
            pointRadiusMinPixels: 8,
            pointRadiusMaxPixels: 12,
            lineWidthMinPixels: 2,
            lineWidthMaxPixels: 8,
            pickable: true,
            getLineColor: (d) => {
                return [255, 255, 255];
            },
            getFillColor: (d) => {
                return d.stop.selectedPt ? [196, 187, 253] : d.stop.master ? [6, 112, 249, 80] : !d.stop.verified ? [220, 53, 69] : d.stop.stopType === 'nonpub' ? [167, 165, 165] : d.stop.stopType === 'school' ? [240, 173, 78] : d.stop.stopType === 'depot' ? [79, 79, 79] : d.stop.stopType === 'venue' ? [64, 144, 162] : [0, 123, 255];
            },
            onClick: handlePickableStopClick,
        });
    }, [handlePickableStopClick]);

    const updateStopsLayer = useCallback(({
                                              route,
                                              allStops,
                                              selectedPt,
                                              setMarkers,
                                              showAllStops,
                                              editingRoute,
                                              allStopsLayer,
                                              setLayers,
                                              setViewState,
                                              selectedTripId,
                                              masterStops
                                          }) => {
        let markers = [];
        if (route && selectedTripId && allStops) {
            const selectedTrip = find(route.services, {tripId: selectedTripId});
            if (selectedTrip?.stopTimes.every(st => Object.keys(allStops).includes(st.stopId))) {
                // console.log('Updating stop sequences.... Stop count: ', route.stops?.length, selectedTrip.stopTimes.map(st => allStops[st.stopId]?.stopName))
                markers = selectedTrip.stopTimes.map((stop, idx) => {
                    return {...stop, ...allStops[stop.stopId], sequence: idx + 1, key: idx};
                });
                setMarkers(markers);
            }
        }

        let allPts = [];
        if (showAllStops && editingRoute) {
            allPts = (masterStops || []).concat(allPts).concat(values(allStops).filter(s => (charter || !s.stopType || s.stopType === 'bus') && (!route || route.stops.findIndex(rs => rs.stopId === s.stopId) === -1)));
        }
        if (selectedPt) {
            allPts = allPts.concat({...selectedPt, selectedPt: true});
        }

        if (allPts?.length) {
            allStopsLayer.current = getPickableStopsLayer({
                id: 'scatter-plot', pts: allPts,
            });
            // allStopsLayer.current = new GeoJsonLayer({
            //     id: 'scatter-plot',
            //     data: toGeoJsonStopCollection(allPts, {offsetSameStops: true}),
            //     // selectedFeatureIndexes: selectedIdx >= 0 ? [selectedIdx] : [],
            //     pickingRadius: 10,
            //     stroked: true,
            //     filled: true,
            //     radiusScale: 5,
            //     pointRadiusMinPixels: 8,
            //     pointRadiusMaxPixels: 12,
            //     lineWidthMinPixels: 2,
            //     lineWidthMaxPixels: 8,
            //     pickable: true,
            //     getLineColor: (d) => {
            //         return [255, 255, 255]
            //     },
            //     getFillColor: (d) => {
            //         return d.stop.selectedPt ? [23, 180, 45] : d.stop.master ? [166, 121, 231] : !d.stop.verified ? [220, 53, 69] :
            //             d.stop.stopType === 'nonpub' ? [167, 165, 165] : d.stop.stopType === 'school' ? [240, 173, 78] :
            //                 d.stop.stopType === 'depot' ? [79, 79, 79] : d.stop.stopType === 'venue' ? [64, 144, 162] :
            //                     [0, 123, 255]
            //     },
            //     onClick: pickInfo => {
            //         const stop = pickInfo.object?.stop;
            //
            //         if (routeBuilding && stop && wpsSelected() && !stop.selectedPt && guidePath?.waypoints?.length) {
            //             route.replacePath(guidePath, selectedStartWpIdx, selectedEndWpIdx).then(() => {
            //                 addSelectedStop(stop).then(() => {
            //                     singleWpSelected(-1)
            //                     setSelectedPt(null)
            //                     setSelectedMarker(null)
            //                     setSelectedStop(null);
            //                     setSelectedMarkers([]);
            //                     setRouteBuilding(false)
            //                     updateRoute(route)
            //                 })
            //             })
            //             return
            //         }
            //
            //         if (stop && !stop.selectedPt) {
            //             handleStopClick(stop, markers);
            //         }
            //         if (stop?.selectedPt) {
            //             console.log('Removing selected Pt.')
            //             setSelectedPt(null);
            //         }
            //     },
            // });
        } else {
            allStopsLayer.current = null;
        }
        setLayers(layers => ({...layers}));

        const allStopsViewState = allStops && Object.keys(allStops).length && getViewportForStops(values(allStops));

        if (route?.waypoints?.length) {
            setViewState(viewState => {
                if ((viewState.latitude === DEFAULT_VIEWSTATE.latitude && viewState.longitude === DEFAULT_VIEWSTATE.longitude) || (allStopsViewState && viewState.latitude === allStopsViewState.latitude && viewState.longitude === allStopsViewState.longitude)) {
                    return getViewport({geos: route.waypoints, padding: 0.02, viewport: viewState});
                }
                return viewState;
            });
        } else if (allStops && Object.keys(allStops).length) {
            setViewState(viewState => {
                if (viewState.latitude === DEFAULT_VIEWSTATE.latitude && viewState.longitude === DEFAULT_VIEWSTATE.longitude) {
                    return allStopsViewState;
                }
                return viewState;
            });
        }
    }, [getPickableStopsLayer]);

    // eslint-disable-next-line
    const routeUpdated = useCallback(debounce((route, allStops) => {

        if (!route) {
            console.log('No route to update..');
            return;
        }

        route.calculateStartEnd({});
        console.log('setting new features for updated route..', route?.waypoints?.length);
        setFeatures(toGeoJsonWithPts(route, route.waypoints));
        updateStopsLayer({
            route,
            allStops,
            selectedPt,
            setMarkers,
            showAllStops,
            editingRoute,
            allStopsLayer,
            setLayers,
            setViewState,
            selectedTripId,
            masterStops
        });

    }, 500), [route, allStops, selectedPt, setMarkers, showAllStops, editingRoute, allStopsLayer, setLayers, setViewState, selectedTripId, updateStopsLayer, masterStops]);

    useEffect(() => {
        // console.log('Updating route...')
        routeUpdated(route, allStops);
    }, [route, allStops, routeUpdated]);

    const setMasterStopsInView = useCallback((masterStops) => {
        if (showAllStops) {
            setMasterStops(masterStops);
        } else {
            setMasterStops([]);
        }
        setLoadingmasterStops(false);
    }, [showAllStops, setMasterStops, setLoadingmasterStops]);

    // eslint-disable-next-line
    // updating stuff in view based on bounds
    const updateStuffInView = useCallback(({
                                               setStopsInView,
                                               setWpIdxsInView,
                                               route,
                                               allStops,
                                               bounds,
                                               showAllStops,
                                               setLayers,
                                               setLoadingmasterStops,
                                               masterStopsLayer,
                                               viewState,
                                           }) => {
        if (!bounds || !viewState) {
            return;
        }
        console.log('Updating stuff in view');
        let minLat = Math.min(...bounds.map((p) => p[1]));
        let minLon = Math.min(...bounds.map((p) => p[0]));
        let maxLat = Math.max(...bounds.map((p) => p[1]));
        let maxLon = Math.max(...bounds.map((p) => p[0]));

        if (allStops && route?.waypoints?.length) {
            console.log('Updating stops in view');
            setStopsInView(values(allStops).filter(stop => stop.lat >= minLat && stop.lat <= maxLat && stop.lon >= minLon && stop.lon <= maxLon));
            const pg = [{latitude: maxLat, longitude: minLon}, {latitude: minLat, longitude: minLon}, {
                latitude: minLat,
                longitude: maxLon
            }, {latitude: maxLat, longitude: maxLon},];
            const wpsInView = route.waypoints.reduce((a, e, i) => {
                if (isPointInPolygon({latitude: e.lat, longitude: e.lon}, pg)) a.push(i);
                return a;
            }, []);
            setWpIdxsInView(wpsInView);
        }

        if (viewState.zoom < 14 || !showAllStops) {
            masterStopsLayer.current = null;
            setLayers({...layers});
            return;
        }

        if (showAllStops) {
            setLoadingmasterStops(true);
            console.log('Updating master stops...');
            fetchMasterStopsInBounds({
                bounds: {
                    minLat, minLon, maxLat, maxLon
                }, excluding: [], types: ['bus']
            }).then(setMasterStopsInView);
        } else {
            setMasterStopsInView([]);
        }
    }, [setMasterStopsInView]);

    const updateStuffInViewDebounced = debounce(updateStuffInView, 250);

    const onViewStateChange = useCallback((viewStateProps) => {
        if (viewStateProps.oldViewState && (viewStateProps.oldViewState.latitude !== viewStateProps.viewState.latitude || viewStateProps.oldViewState.longitude !== viewStateProps.viewState.longitude || viewStateProps.oldViewState.zoom !== viewStateProps.viewState.zoom)) {
            setTolerance(0);
            // setBaseWaypoints(null);
        }

        const viewport = new WebMercatorViewport(viewStateProps.viewState);
        const bounds = viewport.getBoundingRegion();
        setViewState(viewStateProps.viewState);
        updateStuffInViewDebounced({
            setStopsInView,
            setWpIdxsInView,
            route,
            allStops,
            bounds,
            showAllStops,
            setLayers,
            setLoadingmasterStops,
            masterStopsLayer,
            viewState: viewStateProps.viewState,
            setMasterStops,
        });
    }, [setViewState, setTolerance, setStopsInView, setWpIdxsInView, route, allStops, bounds, showAllStops, setLayers, setLoadingmasterStops, masterStopsLayer, setMasterStops]);

    const findRoutesInView = (waypoints) => {
        const bounds = new LngLatBounds(new WebMercatorViewport(viewState).getBounds());
        let subRoutes = {};
        waypoints.forEach((wp, idx) => {
            if (bounds.contains(wp)) {
                const prevWp = waypoints[idx - 1];
                let subRoute = {range: {first: idx, last: idx}, waypoints: []};
                if (wp?.stopTimeId || wp?.checkpoint || prevWp?.stopTimeId || prevWp?.checkpoint) {
                    subRoute.waypoints.push(wp);
                    subRoutes[idx] = subRoute;
                } else if (!wp.stopTimeId && !wp.checkpoint) {
                    subRoute = subRoutes[idx - 1] || subRoute;
                    subRoute.waypoints.push(wp);
                    subRoute.range.last = idx;
                    subRoutes[idx] = subRoute;
                    delete subRoutes[idx - 1];
                }
            }
        });
        console.log(`Sub routes: ${Object.keys(subRoutes)}`);
        console.log(cloneDeep(subRoutes));
        return values(subRoutes);
    };

    const handleSnapViewToRoad = async (route) => {
        let subRoutes = findRoutesInView(route.waypoints);
        await snapSubRoutesToRoadWithMapbox(subRoutes, 0, route, 0);
        return route;
    };

    const snapSubRoutesToRoadWithMapbox = async (subRoutes, idx, route, wpOffset) => {
        console.log('Snapping subroutes...');
        const subRoute = subRoutes[idx];
        console.log('Range first before offset: ', subRoute.range.first);
        subRoute.range.first += wpOffset;
        console.log('Range first after offset: ', subRoute.range.first);
        subRoute.range.last += wpOffset;

        console.log(`Wp count: ${subRoute.waypoints.length}`);
        let wpChunks = chunk(subRoute.waypoints, 100);
        console.log(`Chunks: ${wpChunks.length}`);
        let chunkWpOffset = await snapChunkToRoadWithMapbox(wpChunks, 0, subRoute.range, route);
        console.log('CHUNK WP OFFSEET: ', chunkWpOffset);
        idx += 1;
        if (idx < subRoutes.length) {
            await snapSubRoutesToRoadWithMapbox(subRoutes, idx, route, chunkWpOffset);
        }

    };

    const snapChunkToRoadWithMapbox = async (wpChunks, idx, range, route, batchFn) => {
        const wpChunk = wpChunks[idx];

        console.log(`Snapping chunk with ${wpChunk.length} waypoints with radius: ${snapDistance}`);
        const points = wpChunk.map(wp => {
            return {coordinates: [wp.lon, wp.lat], radius: snapDistance > 0 ? snapDistance : undefined};
        });

        if (wpChunk.length === 1) {
            console.log(`Looks like a stop or checkpoint: ${wpChunk.length} `, range, wpChunk);
            route.waypoints.splice(range.first, wpChunk.length, wpChunk[0]);
            return 0;

        }
        if (points.length < 2 || points.length > 100) {
            console.log(`Wrong number of points for Snapping: ${points.length} `, range, wpChunk);
            return 0;
        }

        let wpOffset = 0;

        return mapMatchingService.getMatch({points, geometries: 'polyline6', steps: true})
            .send()
            .then(async response => {
                if (response.body.matchings && response.body.matchings.length) {
                    // response.body.matchings.forEach(matching => matching.legs.forEach(leg => leg.steps.forEach(leg => console.log(leg.maneuver.type, leg.maneuver.modifier, leg.maneuver.instruction))));
                    // console.log(`Matchings: ${util.inspect(response.body.matchings, {depth: 10})}`)
                    let wps = [];
                    response.body.matchings.forEach(matching => {
                        console.log(matching);
                        const newWayps = waypointsFromPolyline(matching.geometry, 6);
                        if (matching.confidence < 0.5) {
                            throw new Error(`Something wrong with the matching... Leaving.`);
                        }
                        const avgSpd = matching.distance / matching.duration;
                        newWayps.forEach((wp, idx) => {
                            if (idx === 0) {
                                wp.distance = 0;
                                wp.delta = 0;
                                return;
                            }
                            const prevWp = newWayps[idx - 1];
                            const dist = getDistanceInMetres(prevWp, wp);
                            wp.distance = prevWp.distance + dist;
                            wp.delta = prevWp.delta + (dist / avgSpd);
                        });
                        wps = wps.concat(newWayps);
                    });

                    if (batchFn) {
                        batchFn(idx, wps);
                    } else {
                        if (range) {
                            if (range.first > 0) {
                                wps.forEach((wp, idx) => {
                                    wp.delta += route.waypoints[range.first - 1].delta;
                                    wp.delta = Math.round(wp.delta / 60) * 60;
                                    wp.distance += route.waypoints[range.first - 1].distance;
                                });
                            }
                            console.log('Adding %d new wps @ idx: %d. Removing %d wps', wps.length, range.first, wpChunk.length);
                            route.waypoints.splice(range.first, wpChunk.length, ...wps);
                            range.first += wps.length;
                            wpOffset += wps.length - wpChunk.length;
                            //     console.log('Chunk wp offset: ', newWayps.length - wpChunk.length);
                        } else {
                            if (idx === 0) {
                                route.waypoints.length = 0;
                            }

                            if (route.waypoints.length) {
                                wps.forEach(wp => {
                                    wp.delta += last(route.waypoints).delta;
                                    wp.delta = Math.round(wp.delta / 60) * 60;
                                    wp.distance += last(route.waypoints).distance;
                                });
                            }
                            route.waypoints = route.waypoints.concat(wps);
                        }
                    }
                    // Set the delta,distance on the new waypoints
                    // updateRouteDeltas(range.first - newWayps.length, route);
                    // route.calculateStartEnd({});
                    // console.log('WP deltas after snap', route.waypoints.map(wp => ({...wp})))

                    // setFeatures(toGeoJson(route));
                    // setViewFeatures(toSegmentedGeoJson(route));
                    // })
                } else {
                    console.log(`No matches: ${util.inspect(response.body)}`);
                }
                idx += 1;
                if (idx < wpChunks.length) {
                    wpOffset += await snapChunkToRoadWithMapbox(wpChunks, idx, range, route, batchFn);
                }
                return wpOffset;
            });
    };


    useEffect(() => {
        editLayer.current = null;
        if (route && editingRoute && features) {

            editLayer.current = new GeoJsonLayer({
                data: features,
                pickable: true,
                stroked: true,
                filled: true,
                extruded: true,
                pointType: 'circle',
                lineWidthMinPixels: 1,
                lineWidthMaxPixels: 5,
                lineCapRounded: true,
                pointRadiusUnits: 'pixels',
                getLineColor: (feature) => {
                    if ((feature?.properties?.highlight) || (feature?.properties?.wpIdx >= selectedStartWpIdx && feature?.properties?.wpIdx <= selectedEndWpIdx)) {
                        return [255, 255, 255];
                    }
                    return hexToRgb(route.colour);
                },
                getFillColor: (feature) => {
                    if ((feature?.properties?.highlight) || (feature?.properties?.wpIdx >= selectedStartWpIdx && feature?.properties?.wpIdx <= selectedEndWpIdx)) {
                        return [255, 0, 0];
                    }
                    if (feature?.properties?.wpIdx && route.waypoints[feature?.properties?.wpIdx].checkpoint) {
                        return [0, 189, 200];
                    }
                    return [100, 100, 100];
                },
                getPointRadius: (feature) => {
                    if ((feature?.properties?.highlight) || (feature?.properties?.wpIdx >= selectedStartWpIdx && feature?.properties?.wpIdx <= selectedEndWpIdx)) {
                        return 8;
                    }
                    return 5;
                },
                onHover: ({object, coordinate}) => {
                    if (!object?.geometry) {
                        setFeatures(features => {
                            features.features.filter(f => f.geometry.type === 'Point').forEach(f => delete f.properties.highlight);
                            return {...features};
                        });
                        return;
                    }
                    if (object.geometry.type === 'Point') {
                        if (object.properties.wpIdx === 0 && (selectedPt || selectedMarker) && routeBuilding) {
                            // highlight the first waypoint
                            object.properties.highlight = true;
                            setFeatures(features => ({...features}));
                        } else if (singleWpSelected() && object.properties.wpIdx === selectedStartWpIdx + 1 && routeBuilding) {
                            // highlight the first waypoint
                            object.properties.highlight = true;
                            setFeatures(features => ({...features}));
                        } else {
                            setFeatures(features => {
                                features.features.filter(f => f.geometry.type === 'Point').forEach(f => delete f.properties.highlight);
                                return {...features};
                            });
                        }
                        // } else if (object.geometry.type === 'LineString') {
                        // const idx = object.properties.segmentIdx
                        // const [lon, lat] = coordinate
                        // // createWaypoint(idx, lat, lon)
                        // // setFeatures(toGeoJsonWithPts(route))
                        // setSelectedPt({lat, lon})
                    } else {
                        setFeatures(features => {
                            features.features.filter(f => f.geometry.type === 'Point').forEach(f => delete f.properties.highlight);
                            return {...features};
                        });
                    }

                },
                onDragStart: ({object, coordinate}, event) => {
                    if (object.geometry.type !== 'Point' || !object || coordinate?.length !== 2) {
                        return;
                    }
                    const [lon, lat] = coordinate;
                    route.waypoints[object.properties.wpIdx].lat = lat;
                    route.waypoints[object.properties.wpIdx].lon = lon;
                    setFeatures(toGeoJsonWithPts(route));
                    event.stopPropagation();
                },
                onDrag: ({object, coordinate}, event) => {
                    if (object.geometry.type !== 'Point' || !object || coordinate?.length !== 2) {
                        return;
                    }

                    const [lon, lat] = coordinate;
                    // if (singleWpSelected()) {
                    route.waypoints[object.properties.wpIdx].lat = lat;
                    route.waypoints[object.properties.wpIdx].lon = lon;
                    route.waypoints[object.properties.wpIdx].checkpoint = true;
                    setFeatures(toGeoJsonWithPts(route));
                    // } else {
                    //     const positionIndex = object.properties.wpIdx
                    //     const wpCount = route.waypoints.length
                    //     dragPath(route, {lat, lon}, positionIndex, selectedStartWpIdx, selectedEndWpIdx, success => {
                    //         if(success) {
                    //             const addedWps = route.waypoints.length - wpCount
                    //             setFeatures(toGeoJsonWithPts(route))
                    //             setSelectedStartWpIdx(selectedStartWpIdx)
                    //             setSelectedEndWpIdx(selectedEndWpIdx + addedWps)
                    //         }
                    //     })
                    // }
                    event.stopPropagation();
                },
                onDragEnd: ({object, coordinate}, event) => {
                    if (object.geometry.type !== 'Point' || !object || coordinate?.length !== 2) {
                        return;
                    }
                    const positionIndex = object.properties.wpIdx;

                    let movedWp = route.waypoints[positionIndex];
                    const [lon, lat] = coordinate;
                    movedWp.lat = lat;
                    movedWp.lon = lon;

                    if (singleWpSelected() || selectedEndWpIdx === -1 || selectedStartWpIdx === -1) {

                        let newWpDist = 0, newWpDelta = 0, newNextWpDist = 0, newNextWpDelta = 0, additionalDist = 0,
                            additionalDelta = 0;
                        if (positionIndex > 0) { // if not first waypoints
                            const dist = getDistanceInMetres(movedWp, route.waypoints[positionIndex - 1]);
                            newWpDist = dist + route.waypoints[positionIndex - 1].distance;
                            newWpDelta = dist / route.getAvgSpeed(positionIndex - 1) + route.waypoints[positionIndex - 1].delta;
                            additionalDist = newWpDist - movedWp.distance;
                            additionalDelta = newWpDelta - movedWp.delta;
                        }

                        if (route.waypoints.length > positionIndex + 1) { // If not last waypoint
                            const dist = getDistanceInMetres(movedWp, route.waypoints[positionIndex + 1]);
                            newNextWpDist = dist + newWpDist;
                            newNextWpDelta = dist / route.getAvgSpeed(positionIndex - 1) + newWpDelta;

                            additionalDist += route.waypoints[positionIndex + 1].distance - newNextWpDist;
                            additionalDelta += route.waypoints[positionIndex + 1].delta - newNextWpDelta;
                        }

                        // console.log(newWpDist, newWpDelta, route.waypoints[positionIndex - 1].delta, additionalDist, additionalDelta)

                        movedWp.distance = newWpDist;
                        movedWp.delta = newWpDelta;
                        movedWp.delta = Math.round(movedWp.delta / 60) * 60;

                        // let additionalDistance = positionIndex > 0 ? getDistanceInMetres(movedWp, route.waypoints[positionIndex-1]) - route.waypoints[positionIndex].distance : 0
                        // additionalDistance += route.waypoints.length > positionIndex+1 ? getDistanceInMetres(movedWp, route.waypoints[positionIndex+1]) - route.waypoints[positionIndex].distance : 0
                        // let additionalDelta = route.waypoints.length > positionIndex+1  ? additionalDistance / route.getAvgSpeed(positionIndex-1) : 0
                        route.waypoints.forEach((wp, idx) => {
                            if (idx > positionIndex + 1) {
                                wp.delta += additionalDelta;
                                wp.delta = Math.round(wp.delta / 60) * 60;
                                wp.distance += additionalDist;
                            }
                        });

                        setFeatures(toGeoJsonWithPts(route));
                        updateRoute(route, false);
                    } else if (routeBuilding && selectedStartWpIdx > 0 && selectedEndWpIdx > selectedStartWpIdx) {
                        route.insertPath({lat, lon}, positionIndex, selectedStartWpIdx, selectedEndWpIdx).then(() => {
                            setFeatures(toGeoJsonWithPts(route));
                            // singleWpSelected(-1)
                            updateRoute(route, false);
                        });
                    }
                    // updateWaypoints(route)
                    console.log('finished move position');
                    // singleWpSelected(positionIndex)
                    event.stopPropagation();
                },
                onClick: ({object, coordinate}, input) => {
                    console.log(object, input, coordinate);

                    if (object.geometry.type === 'Point') {
                        const index = object.properties.wpIdx;
                        if (!Number.isFinite(index) || index < 0 || index >= route.waypoints.length) {
                            setSelectedStartWpIdx(-1);
                            setSelectedEndWpIdx(-1);
                            return;
                        }
                        const [lon, lat] = object.geometry.coordinates;
                        const closestDist = route.waypoints.reduce((c, n) => {
                            const distToN = getDistanceInMetres(n, {lat, lon});
                            if (distToN < c) {
                                return distToN;
                            }
                            return c;
                        }, Number.MAX_SAFE_INTEGER);
                        console.log('Closest dist: ', closestDist);
                        if (closestDist > 20) {
                            createWaypoint(index, lat, lon);
                            singleWpSelected(index);
                            return;
                        }

                        if (selectedStartWpIdx === index && selectedEndWpIdx === index) {
                            singleWpSelected(-1);
                        } else if (routeBuilding && selectedPt && index === 0) {
                            // Route build from clicked point to first stop
                            appendPathToPt(selectedPt, 'prepend').then(() => {
                                singleWpSelected(-1);
                                setSelectedPt(null);
                                setRouteBuilding(false);
                                setSelectedMarker(null);
                                setSelectedStop(null);
                                setSelectedMarkers([]);
                            });
                        } else if (routeBuilding && selectedMarker && index === 0) {
                            // Route build from select stop to first stop
                            addSelectedStop(route, selectedMarker, 'prepend').then(() => {
                                singleWpSelected(-1);
                                setSelectedMarker(null);
                                setSelectedStop(null);
                                setSelectedMarkers([]);
                                setRouteBuilding(false);
                            });
                        } else if (routeBuilding && singleWpSelected() && index === selectedStartWpIdx + 1) {
                            appendPathToPt(route.waypoints[index], 'append', selectedStartWpIdx).then(() => {
                                singleWpSelected(-1);
                                setSelectedMarker(null);
                                setSelectedStop(null);
                                setSelectedMarkers([]);
                                setRouteBuilding(false);
                            });
                        } else {
                            if (input.srcEvent.shiftKey) {
                                if (index < selectedStartWpIdx) {
                                    setSelectedStartWpIdx(index);
                                    if (selectedEndWpIdx < selectedStartWpIdx) {
                                        setSelectedEndWpIdx(index);
                                    }
                                } else if (index > selectedEndWpIdx) {
                                    setSelectedEndWpIdx(index);
                                    if (selectedStartWpIdx > selectedEndWpIdx) {
                                        setSelectedStartWpIdx(index);
                                    }
                                }
                            } else {
                                setSelectedPt(null);
                                setSelectedStartWpIdx(index);
                                setSelectedEndWpIdx(index);
                                setSelectedMarker(null);
                                setSelectedStop(null);
                                setSelectedMarkers([]);
                            }
                        }
                    }
                    if (routeBuilding && object.geometry.type === 'LineString') {
                        const selectedPt = {lat: coordinate[1], lon: coordinate[0]};

                        const wpCount = route.waypoints.length;

                        if (selectedMarker) {
                            const {nearestIdx: wpIdx} = getNearestWaypoint(selectedMarker, route.waypoints);
                            if (wpIdx > -1 && wpIdx < wpCount) {
                                appendPathToPt(selectedPt, 'append', wpIdx).then(() => {
                                    singleWpSelected(wpIdx + route.waypoints.length - wpCount);
                                    setSelectedPt(null);
                                    setSelectedMarker(null);
                                    setSelectedStop(null);
                                    setSelectedMarkers([]);
                                });
                            }
                        } else if (singleWpSelected()) {
                            appendPathToPt(selectedPt, 'append', selectedStartWpIdx, selectedEndWpIdx).then(() => {
                                singleWpSelected(selectedStartWpIdx + route.waypoints.length - wpCount);
                                setSelectedPt(null);
                            });
                        }
                    } else if (object.geometry.type === 'LineString') {
                        const idx = object.properties.segmentIdx + 1;
                        const [lon, lat] = coordinate;
                        createWaypoint(idx, lat, lon);
                        setFeatures(toGeoJsonWithPts(route));
                        return;
                    }
                    console.log('Waypoint count: ', route.waypoints.length);
                }
            });
        }
        setLayers(layers => ({...layers}));
    }, [colour, route, editingRoute, features, tolerance, createWaypoint, updateRoute, selectedStartWpIdx, selectedEndWpIdx, setSelectedStartWpIdx, setSelectedEndWpIdx, setRouteBuilding, routeBuilding, addSelectedStop, appendPathToPt, selectedMarker, selectedPt, setSelectedStop, singleWpSelected]);

    const turnOnRouteBuilding = useCallback(() => {
        if (editingRoute && (selectedPt || selectedMarker)) {
            if (selectedPt && !route.waypoints?.length) {
                appendPathToPt(selectedPt).then(noop);
                setSelectedStartWpIdx(0);
                setSelectedEndWpIdx(0);
            } else if (selectedMarker && !route.stops?.length) {
                addSelectedStop(route, selectedMarker, 'add', true).then(noop);
            }
        }
        setRouteBuilding(true);
    }, [route, selectedPt, selectedMarker, setRouteBuilding, addSelectedStop, appendPathToPt, editingRoute, setSelectedStartWpIdx, setSelectedEndWpIdx]);

    useEffect(() => {
        if (editingRoute && routeBuilding && (selectedPt || selectedMarker || wpsSelected()) && mouseLocation?.length === 2) {
            const fromLocation = selectedPt || selectedMarker || route.waypoints[selectedStartWpIdx];
            const toLocation = selectedEndWpIdx > selectedStartWpIdx ? route.waypoints[selectedEndWpIdx] : null;
            if (fromLocation) {
                const [mouseLon, mouseLat] = mouseLocation;
                const coordinates = [[fromLocation.lon, fromLocation.lat], mouseLocation];
                const checkpoint = {lat: parseFloat(mouseLat.toFixed(6)), lon: parseFloat(mouseLon.toFixed(6))};
                const coords = [fromLocation, checkpoint];
                if (toLocation) {
                    coordinates.push([toLocation.lon, toLocation.lat]);
                    coords.push(toLocation);
                }
                setGuidePath({
                    geojson: {
                        type: 'Feature', geometry: {
                            type: 'LineString', coordinates
                        }
                    }
                });

                getCachedPathFromCoordsDebounced('bus', coords, null).then(path => {
                    // if (path.waypoints?.length) {
                    //     // let maybeCheckpointWp = path.waypoints.find(wp => wp.lat === checkpoint.lat && wp.lon === checkpoint.lon);
                    //     const nearestSegment = getNearestSegment(checkpoint, path.waypoints);
                    //     if (nearestSegment?.segment?.nearestWp) {
                    //         nearestSegment.segment.nearestWp.checkpoint = true;
                    //     }
                    // }
                    setGuidePath(path);
                });
            }
        }
    }, [routeBuilding, selectedPt, selectedMarker, selectedStartWpIdx, selectedEndWpIdx, mouseLocation, route, editingRoute, setGuidePath, wpsSelected]);

    useEffect(() => {
        console.log('New guidePath: ', guidePath);
        if (!guidePath || !routeBuilding || !editingRoute) {
            guideLayer.current = null;
        } else {
            guideLayer.current = new GeoJsonLayer({
                id: 'route-guide-layer',
                data: {...guidePath.geojson},
                stroked: false,
                filled: true,
                extruded: true,
                pointType: 'circle',
                lineWidthScale: 1,
                lineWidthMinPixels: 2,
                lineWidthMaxPixels: 5,
                getFillColor: [160, 160, 180, 200],
                getLineColor: d => [255, 0, 0],
                getPointRadius: 100,
                getLineWidth: 1,
                getElevation: 30, // props added by PathStyleExtension
                getDashArray: (feature) => {
                    return [4, 3];
                },
                dashJustified: false,
                extensions: [new PathStyleExtension({highPrecisionDash: true})],
            });
        }

        setLayers(layers => ({...layers}));

    }, [guideLayer, guidePath, setLayers, routeBuilding, editingRoute]);
// const handleOptimisation = (toleranceVal, route) => {
//     let _route = new BusRoute({...route});
//
//     let _baseWaypoints = baseWaypoints;
//     if (!_baseWaypoints) {
//         const subRoutes = findRoutesInView(route.waypoints);
//         _baseWaypoints = {subRoutes: subRoutes, waypoints: route.waypoints};
//         setBaseWaypoints(_baseWaypoints);
//     }
//
//     if (toleranceVal > 0) {
//
//         let waypoints = [..._baseWaypoints.waypoints];
//         let diff = 0;
//         _baseWaypoints.subRoutes.forEach(subRoute => {
//
//             let optimisedWps = []
//             let prevWp = null;
//             subRoute.waypoints.forEach((wp, idx) => {
//                 if (idx === 0 || idx === subRoute.waypoints.length - 1) {
//                     optimisedWps.push(wp);
//                     prevWp = wp;
//                     return
//                 }
//                 const distance = getDistanceInMetres(wp, prevWp)
//                 if (distance >= toleranceVal) {
//                     optimisedWps.push(wp);
//                     prevWp = wp;
//                 }
//             })
//
//             let removalCount = subRoute.range.last - subRoute.range.first + 1;
//             let replacementCount = optimisedWps.length
//             waypoints.splice(subRoute.range.first + diff, removalCount, ...optimisedWps);
//             diff = diff + replacementCount - removalCount
//         })
//         _route.waypoints = waypoints;
//     }
//     _route.calculateStartEnd({});
//     updateRoutePathFn(_route.waypoints)
//     // setRoute(_route)
//
//     // setFeatures(toGeoJson(_route));
//     // setViewFeatures(toSegmentedGeoJson(_route));
//     setTolerance(toleranceVal);
// }

    const canTrimSelectedMarker = useCallback(() => {
        if (!selectedMarker) {
            return false;
        }
        const idxOfSelectedStop = route.stops.findIndex(s => s.stopTimeId === selectedMarker.stopTimeId);
        return selectedMarker && idxOfSelectedStop < route.stops.length && idxOfSelectedStop >= 0 && route.stops.length > 0;
    }, [route, selectedMarker]);

    const cut = useCallback((startWpIdx, endWpIdx) => {
        if (startWpIdx >= endWpIdx) {
            return;
        }

        if (!window.confirm(`Are you sure you want to cut the route between the selected waypoints?`)) {
            return;
        }

        console.log(`Cutting route.`);

        const _route = route.clone();
        console.log('wps before cut:', _route.waypoints.length);
        _route.cut(startWpIdx, endWpIdx);
        console.log('wps after cut:', _route.waypoints.length);

        updateRouteFn(_route);
    }, [route, updateRouteFn]);

    const handleDelete = useCallback(() => {
        if (selectedStartWpIdx > -1 && selectedEndWpIdx >= selectedStartWpIdx) {
            if (singleWpSelected()) {
                route.waypoints.splice(selectedStartWpIdx, 1);
                updateRoute(route);
            } else {
                if (handleCut) {
                    handleCut(selectedStartWpIdx, selectedEndWpIdx);
                } else {
                    cut(selectedStartWpIdx, selectedEndWpIdx);
                }
                singleWpSelected(selectedStartWpIdx - 1);
            }
        } else if (selectedMarker) {
            deleteSelectedStop(selectedMarker);
        }
        setSelectedPt(null);
        setSelectedMarker(null);
        setSelectedMarkers([]);
        setSelectedStop(null);
    }, [selectedStartWpIdx, selectedEndWpIdx, singleWpSelected, route, updateRoute, handleCut, cut,
        deleteSelectedStop, setSelectedPt, setSelectedMarker, setSelectedMarkers, setSelectedStop]);

    const keyPressed = useCallback(async ({key}) => {
        console.log(key, ' pressed');

        if (showCreateStop || geocoder || showComments) {
            return;
        }
        if (key === 'e') {
            setEditingRoute(er => {
                if (er) {
                    singleWpSelected(-1);
                    setSelectedPt(null);
                    setSelectedMarker(null);
                    setSelectedMarkers([]);
                    setRouteBuilding(false);
                }
                return !er;
            });
        }
        if (editingRoute && selectedPt && !selectedMarker && key === 'c') {
            const nearestSegment = getNearestSegment(selectedPt, route.waypoints, {maxDistFrom: 50});
            if (!nearestSegment) {
                await handleBuildRoute('append', true);
            }
            setShowCreateStop(true);
        }
        if (editingRoute && canEnableBuild() && key === 'b') {
            // await handleBuildRoute()
            // singleWpSelected(-1);
            if (!routeBuilding) {
                turnOnRouteBuilding();
            } else {
                setRouteBuilding(false);
            }
        }
        if (editingRoute && ['Delete', 'Backspace'].includes(key)) {
            handleDelete();
        }
        if (editingRoute && key === 's') {
            setIsSnapping(true);
            try {
                setSelectedPt(null);
                setSelectedStartWpIdx(0);
                setSelectedEndWpIdx(0);
                setSelectedMarker(null);
                setSelectedMarkers([]);
                const _route = cloneDeep(route);
                await handleSnapViewToRoad(_route);
                updateRoute(_route);
            } catch (e) {
                console.log(e);
                messageApi.open({
                    type: 'error',
                    duration: 5,
                    content: 'Snap to road failed. Please check your waypoints are within the snap to road distance.',
                });
            }
            setIsSnapping(false);
        }
        if (editingRoute && routeBuilding && key.toLowerCase().startsWith('esc')) {
            singleWpSelected(-1);
            setSelectedPt(null);
            setSelectedMarker(null);
            setSelectedMarkers([]);
            setRouteBuilding(false);
            setSelectedStop(null);
        }
        // if (editingRoute && selectedMarker && key === 'e') {
        //     await addSelectedStop(selectedMarker, 'append');
        // } else if (editingRoute && selectedPt && key === 'e') {
        //     await appendPathToPt(selectedPt);
        // }
    }, [editingRoute, routeBuilding, selectedMarker, selectedPt, wpsSelected, setShowCreateStop, canEnableBuild,
        singleWpSelected, showCreateStop, setSelectedStop, setSelectedPt, setSelectedMarker, setSelectedMarkers,
        geocoder, setIsSnapping, setSelectedStartWpIdx, setSelectedEndWpIdx, updateRoute, setRouteBuilding,
        turnOnRouteBuilding, showComments]);

    useEffect(() => {
        routeMapRef.current.addEventListener('keyup', keyPressed);
        return () => {
            routeMapRef.current.removeEventListener('keyup', keyPressed);
        };
    }, [keyPressed]);

    const trim = useCallback((truncate, selectedStop) => {

        if (!window.confirm(`Are you sure you want to trim the route${truncate ? ` from ${selectedStop.stopName} to the end?` : ` from the start to ${selectedStop.stopName}?`}`)) {
            return;
        }

        console.log(`Trimming route. Truncate: ${truncate}. Selected marker: ${util.inspect(selectedStop)}`);

        if (selectedStop) {

            const _route = route.clone();
            console.log('wps before trim:', route.waypoints.length);
            _route.trim(selectedStop, truncate);
            console.log('wps after trim:', route.waypoints.length);

            console.log('Finish trim');
            const newRoute = new BusRoute(_route);
            console.log('wps after after trim:', newRoute.waypoints.length);
            // setStops([...route.stops]);
            newRoute.calculateStartEnd({stops: route.stops});
            updateRouteFn(_route);
            // handleRoutePathUpdate(newRoute.waypoints)
            // setBaseWaypoints([...route.waypoints])
        }
    }, [route, updateRouteFn]);

    useEffect(() => {
        if (listening) return;
        if (!routeMapRef.current) return;
        setListening(true);
        [`click`, `touchstart`].forEach(type => {
            document.addEventListener(type, (evt) => {
                if (!routeMapRef.current || routeMapRef.current.contains(evt.target)) return;
                setGuidePath(null);
                setRouteBuilding(false);
            });
        });
    }, [listening, setListening, routeMapRef, setGuidePath, setRouteBuilding, routeBuilding, singleWpSelected, setSelectedPt, setSelectedMarker, setSelectedStop, setSelectedMarkers]);

    const onResult = useCallback((event) => {
        if (event.result.place_type.indexOf('stop') > -1) {
            const stop = event.result.properties.stop;
            handleStopClick(stop);
            setPoiMarker(null);
            setPopupData(null);
        } else {
            setPoiMarker(event.result);
            setPopupData(event.result);
        }
    }, [props.setSelectedStop, setPoiMarker, setPopupData]);

    const theLocalGeoCoder = useCallback((query) => {
        return localGeocoder({
            query, allStops: allStops, verifiedOnly: false
        });
    }, [allStops]);

    const handleGeocoderViewportChange = useCallback((newViewport) => {
        const geocoderDefaultOverrides = {transitionDuration: 1000};
        setViewState({
            ...newViewport, ...geocoderDefaultOverrides
        });
    }, [setViewState]);

    const getChatControlPanel = useCallback(() => {
        return <ControlPanel
            key={`CtrlPnl-chat-${route?.routeId || 'new'}`}
            immutable={true} className="ControlPanel CommentsPanel"
        >
            <RouteChat
                chatContext={new ChatContext({
                    type: 'route',
                    id: route.routeId,
                    route,
                    subType: 'wp',
                    showAllComments: true,
                    setFocusPt: props.setFocusPt
                })}
                selectedChatContext={selectedChatContext}
                newChatContext={chatContext}
                editor={editor}/>
        </ControlPanel>;
    }, [chatContext, props.setFocusPt, editor]);

    const addCheckpointsWpToWp = async (busablePath) => {
        // const busablePath = route.waypoints.slice(checkpoints[0], last(checkpoints));
        const checkpoints = busablePath.filter(wp => wp.stopTimeId || wp.checkpoint);
        const mapboxPath = await getCachedPathMapbox('driving', checkpoints);
        if (busablePath?.length && mapboxPath?.waypoints?.length) {
            const divergent = findDivergenceByDistance(busablePath, mapboxPath.waypoints);
            if (divergent?.nearestWaypoint) {
                divergent.nearestWaypoint.nearest.checkpoint = true;
            }
            return divergent;
        }
    };

    const addCheckpointsStopToStop = async (route, stopIdx1, stopIdx2) => {
        const path = route.waypoints.slice(stopIdx1, stopIdx2 + 1);
        let currentCheckpointIdx = findIndex(path, wp => wp.stopTimeId || wp.checkpoint, 0);
        let attempts = 0;
        await whilst(
            cb => {
                cb(null, attempts++ < 50 && currentCheckpointIdx > -1 && currentCheckpointIdx < path.length - 1);
            },
            cb => {
                let nextCheckpointIdx = findIndex(path, wp => wp.stopTimeId || wp.checkpoint, currentCheckpointIdx + 1);
                const busablePath = path.slice(0, nextCheckpointIdx + 1);
                addCheckpointsWpToWp(busablePath).then(divergent => {
                    currentCheckpointIdx = divergent?.nearestWaypoint?.index || nextCheckpointIdx;
                    cb();
                });
            }
        );
        console.log('Finished adding checkpoints between ', stopIdx1, ' and ', stopIdx2);
    };
    const addCheckpoints = useCallback(async (route) => {
        setCalculatingCheckpoints(true);

        let currentStopIdx = findIndex(route.waypoints, wp => wp.stopTimeId || wp.checkpoint, 0);
        let attempts = 0;
        await whilst(
            cb => {
                cb(null, attempts++ < 50 && currentStopIdx > -1 && currentStopIdx < route.waypoints.length - 1);
            },
            cb => {
                let nextStopIdx = findIndex(route.waypoints, wp => wp.stopTimeId || wp.checkpoint, currentStopIdx + 1);
                addCheckpointsStopToStop(route, currentStopIdx, nextStopIdx).then(() => {
                    currentStopIdx = nextStopIdx;
                    cb();
                });
            },
            err => {
                if (err) {
                    console.log(err);
                    return;
                }
                route.optimiseCheckpoints();
                updateRoute(route);
            }
        );
        // await series(route.waypoints.filter(wp => wp.stopTimeId || wp.checkpoint).map(async (wp, idx) => {
        //     if (idx === 0) {
        //         return;
        //     }
        //     const wpIdx1 = route.waypoints.findIndex(wp => wp.stopTimeId === stopTime1.stopTimeId)
        //     await addCheckpointsStopToStop(route, route.stops[idx - 1], stop);
        // }));
        setCalculatingCheckpoints(false);
    }, [updateRoute, setCalculatingCheckpoints]);

    const setCheckpointOnWp = useCallback(() => {
        if (singleWpSelected()) {
            const wp = route.waypoints[selectedStartWpIdx];
            wp.checkpoint = !wp.checkpoint;
            updateRoute(route, false);
        } else if (selectedStartWpIdx >= 0) {
            route.waypoints.slice(selectedStartWpIdx, selectedEndWpIdx + 1).forEach(wp => wp.checkpoint = false);
            updateRoute(route, false);
        }
    }, [route, updateRoute, singleWpSelected, selectedStartWpIdx, selectedEndWpIdx]);

    logger.trace('Refreshing DOM: RouteMap');
    return (<div ref={routeMapRef}>
        <>
            {preloadImgData.map((data, idx) => <span key={`BG-Img-${idx}`} style={{background: data}}/>)}
        </>
        {contextHolder}
        {route && route.waypoints && viewState ? <>
            <div className="ControlPanelContainer">
                {!hideContextMenu && selectedMarkers.length ? selectedMarkers.map(selectedMarker => (
                    <ControlPanel key={`CtrlPnl-${selectedMarker.stopTimeId}`}
                                  immutable={immutable || !route.waypoints?.length}
                                  editing={selectedMarker.editing}
                                  delControlFn={selectedMarker.stopTimeId ? () => {
                                      deleteSelectedStop(selectedMarker);
                                  } : null}
                                  closeControlFn={() => {
                                      setSelectedMarker(null);
                                      setSelectedMarkers(selectedMarkers.filter(m => selectedMarker.stopTimeId !== m.stopTimeId));
                                      if (deselectStop) {
                                          deselectStop();
                                      }
                                  }}
                    >

                        <StopInfo
                            onStopSequenceChange={(sequence) => {
                                if (!window.confirm(`Are you sure you want to move ${selectedMarker.stopName} to sequence ${sequence}?`)) {
                                    return;
                                }
                                route.setStopSequence(selectedMarker, sequence);
                                setSelectedMarkers(selectedMarkers.filter(m => selectedMarker.stopTimeId !== m.stopTimeId));
                                updateRouteFn(route);
                            }}
                            idx={markers.findIndex(stop => stop.stopTimeId === selectedMarker.stopTimeId)}
                            stopCount={markers.length}
                            startTime={startTime} stop={selectedMarker}/>
                    </ControlPanel>)) : <></>}
                {allowComments && showComments && getChatControlPanel()}
            </div>
            <DeckGL
                style={{overflow: 'hidden', borderRadius: '8px 0 0 8px'}}
                // initialViewState={}
                viewState={viewState}
                controller={mapController}
                // getCursor={layer.getCursor.bind(layer)}
                ContextProvider={_MapContext.Provider}
                layers={flatten(Object.keys(layers).map(layerKey => {
                    return layers[layerKey].current;
                }))}
                // layers={[editLayer.current]}
                onViewStateChange={onViewStateChange}
                onClick={(info, e) => {
                    if (e?.target?.id !== 'view-default-view') {
                        return;
                    }
                    setPopupData(null);
                    if (editingRoute && !info.object) {
                        const selectedPt = {lat: info.coordinate[1], lon: info.coordinate[0]};
                        setSelectedPt(selectedPt);

                        // Add a checkpoint to the nearest waypoint
                        if (selectedPt && routeBuilding && guidePath?.waypoints?.length) {
                            const {nearestIdx: wpIdx} = getNearestWaypoint(selectedPt, guidePath.waypoints);
                            if (wpIdx > -1 && wpIdx < guidePath.waypoints.length) {
                                guidePath.waypoints[wpIdx].checkpoint = true;
                            }
                        }

                        if (routeBuilding && selectedMarker && guidePath?.waypoints?.length) {
                            const {nearestIdx: wpIdx} = getNearestWaypoint(selectedMarker, route.waypoints);
                            if (wpIdx > -1 && wpIdx < route.waypoints.length) {
                                const wpCount = route.waypoints.length;
                                route.replacePath(guidePath, wpIdx).then(() => {
                                    // appendPathToPt(selectedPt, 'append', wpIdx).then(() => {
                                    singleWpSelected(wpIdx + route.waypoints.length - wpCount);
                                    setSelectedPt(null);
                                    setSelectedMarker(null);
                                    setSelectedStop(null);
                                    setSelectedMarkers([]);
                                    updateRoute(route);
                                });
                            } else {
                                singleWpSelected(-1);
                                setSelectedStop(null);
                                setSelectedMarker(null);
                                setSelectedMarkers([]);
                                setRouteBuilding(false);
                            }
                        } else if (routeBuilding && singleWpSelected() && guidePath?.waypoints?.length) {
                            const wpCount = route.waypoints.length;
                            route.replacePath(guidePath, selectedStartWpIdx).then(() => {
                                // appendPathToPt(selectedPt, 'append', selectedStartWpIdx, selectedEndWpIdx).then(() => {
                                singleWpSelected(selectedStartWpIdx + route.waypoints.length - wpCount);
                                setSelectedPt(null);
                                setSelectedMarker(null);
                                setSelectedStop(null);
                                setSelectedMarkers([]);
                                updateRoute(route);
                            });
                        } else if (routeBuilding && wpsSelected() && guidePath?.waypoints?.length) {
                            // Divert route from selected waypoints
                            route.replacePath(guidePath, selectedStartWpIdx, selectedEndWpIdx).then(() => {
                                // setFeatures(toGeoJsonWithPts(route))
                                singleWpSelected(-1);

                                // singleWpSelected(selectedStartWpIdx + route.waypoints.length - wpCount)
                                setSelectedPt(null);
                                setSelectedMarker(null);
                                setSelectedStop(null);
                                setSelectedMarkers([]);
                                setRouteBuilding(false);
                                updateRoute(route);
                            });
                        } else {
                            setRouteBuilding(false);
                            singleWpSelected(-1);
                            setSelectedStop(null);
                            setSelectedMarker(null);
                            setSelectedMarkers([]);
                        }
                    } else if (!info.object) {
                        setSelectedMarker(null);
                        setSelectedStop(null);
                        setSelectedMarkers([]);
                    }
                }}

                onHover={(e) => {

                    if (!e.viewport) {
                        return;
                    }
                    if (e.coordinate && editingRoute && routeBuilding) {
                        setMouseLocation(mouseLocation => {
                            if (!mouseLocation) {
                                return e.coordinate;
                            }
                            const [mouseLon, mouseLat] = mouseLocation;
                            const current = {lat: mouseLat, lon: mouseLon};
                            const [newLon, newLat] = e.coordinate;
                            const newLoc = {lat: newLat, lon: newLon};
                            if (getDistanceInMetres(current, newLoc) < 10) {
                                return mouseLocation;
                            }
                            return e.coordinate;
                        });
                    }
                }}
                getTooltip={(info) => {

                    if (info.object) {
                        const coordinate = {
                            lon: info.coordinate[0], lat: info.coordinate[1]
                        };
                        let bearing = getBearingAtCoordinate(coordinate, route);
                        if (bearing === undefined) {
                            return;
                        }
                        let bearingInt = Math.round(bearing);
                        return {
                            style: {
                                background: `url('data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path transform='rotate(${bearingInt - 90}, 256, 256)' d='M504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256zm72 20v-40c0-6.6 5.4-12 12-12h116v-67c0-10.7 12.9-16 20.5-8.5l99 99c4.7 4.7 4.7 12.3 0 17l-99 99c-7.6 7.6-20.5 2.2-20.5-8.5v-67H140c-6.6 0-12-5.4-12-12z'/></svg>')`,
                                backgroundColor: '#fff',
                                borderRadius: '10px',
                                marginTop: '-20px'
                            }
                        };
                    }
                }}
                pickingRadius={10}
            >
                <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                           mapStyle={MAPBOX_STYLES[style]} ref={mapRef}/>
                {

                    markers.map((marker, index) => {
                        return (<Marker
                            key={`RouteMarker-${index}`}
                            offsetTop={-10}
                            offsetLeft={-10}
                            latitude={marker.lat}
                            longitude={marker.lon}
                            draggable={!props.routeOnly && marker.editing}
                            onDragEnd={(e) => onMarkerDragEnd(e, marker.stopId)}
                        >
                            <Pin size={25} type={marker.stopType} sequence={marker.sequence}
                                 selected={selectedMarker && marker.stopId === selectedMarker.stopId}
                                 focused={focusMarker && marker.stopId === focusMarker.stopId}
                                 onClick={() => {
                                     if (!props.routeOnly) {
                                         handleStopClick(marker, markers);
                                         setSelectedPt(null);
                                     }
                                 }}
                            />
                        </Marker>);
                    })

                }
                {transferMarker && <Marker
                    key={`TransferMarker`}
                    offsetTop={-40}
                    offsetLeft={-20}
                    latitude={transferMarker.lat || transferMarker.center[1]}
                    longitude={transferMarker.lon || transferMarker.center[0]}>
                    <TransferPin/>
                </Marker>}
                {poiMarker && (<Marker
                    key={`POIMarker`}
                    offsetTop={-40}
                    offsetLeft={-20}
                    latitude={poiMarker.lat || poiMarker.center[1]}
                    longitude={poiMarker.lon || poiMarker.center[0]}>
                    <Image width={'40px'}
                           src="https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/location-pin.png"/>
                </Marker>)}
                <MapPopup popupData={popupData} setPopupData={setPopupData}
                          setSelectedStop={setSelectedStop} {...props}/>
                <div className="info-text-wrap w-label map-info">
                    <div className="icon-info-text">
                        <Services/>
                        <div>
                            <span>Distance</span>
                            {route?.waypoints && toKmMs(last(route.waypoints)?.distance)}
                        </div>
                    </div>
                </div>
                <div className="nav" style={navStyle}>
                    <NavigationControl/>
                </div>

                <div className="MapSearch">
                    <div ref={geocoderContainerRef}/>
                </div>
                <Geocoder
                    mapRef={mapRef}
                    containerRef={geocoderContainerRef}
                    onViewportChange={handleGeocoderViewportChange}
                    mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                    zoom={17}
                    limit={100}
                    clearAndBlurOnEsc={true}
                    clearOnBlur={true}
                    countries={'AU'}
                    placeholder={'Search ...'}
                    collapsed={false}
                    localGeocoder={theLocalGeoCoder}
                    render={renderSuggestions}
                    onResult={onResult}
                    onInit={geocoder => {
                        geocoder.setInput('');
                        if (!geocoder._inputEl.geocoderListener) {
                            geocoder._inputEl.style.backgroundColor = '#d6d6d6';
                            geocoder._inputEl.style.opacity = '0.5';
                            geocoder._inputEl.disabled = true;
                            if (!loadingmasterStops) {
                                debounce(() => {
                                    geocoder._inputEl.style.backgroundColor = '#fefefe';
                                    geocoder._inputEl.style.opacity = '1.0';
                                    geocoder._inputEl.disabled = false;
                                }, 500)();
                            }
                            geocoder._inputEl.addEventListener('keyup', e => {
                                e.stopPropagation();
                            });
                            geocoder.container.addEventListener('mousemove', e => {
                                e.stopPropagation();
                            });
                            geocoder._inputEl.geocoderListener = true;
                        }
                    }}
                />
                <MapToolbar style={{position: 'absolute', top: 110, left: 10}}>
                    <MapLayerToolbar setLayer={setStyle}/>

                    {allowComments &&
                        <Button className={`mapbox-ctrl-icon ${showComments ? 'btn-light-active' : ''}`}
                                variant={'light'}
                                onClick={() => setShowComments(c => !c)}>
                            <Chat/>
                        </Button>}
                </MapToolbar>
                <MapEditorToolbar style={{position: 'absolute', top: allowComments ? 182 : 155, left: 10}}
                                  route={route}
                                  charter={charter}
                                  allStops={allStops}
                                  routeOnly={props.routeOnly}
                                  stops={stopsInView}
                                  isExtending={isExtending}
                                  isSnapping={isSnapping}
                                  selectedPt={selectedPt}
                                  selectedMarker={selectedMarker}
                                  selectedStartWpIdx={selectedStartWpIdx}
                                  selectedEndWpIdx={selectedEndWpIdx}
                                  showAllStops={showAllStops}
                                  setShowAllStops={setShowAllStops}
                                  routeBuilding={routeBuilding}
                                  setRouteBuilding={rb => {
                                      if (rb) {
                                          turnOnRouteBuilding();
                                      } else {
                                          setRouteBuilding(rb);
                                      }
                                  }}
                                  wpIdxsInView={wpIdxsInView}
                                  setSelectedStartWpIdx={setSelectedStartWpIdx}
                                  setSelectedEndWpIdx={setSelectedEndWpIdx}
                                  setShowCreateStop={setShowCreateStop}
                                  showCreateStop={showCreateStop}
                                  createNewStop={async (stop, buildToStop) => {
                                      await createNewStop(stop);
                                      setSelectedMarker(stop);
                                  }}
                                  wpOptimisationCount={route.optimiseWaypointResult()}
                                  handleOptimise={() => {
                                      route.optimiseWaypoints();
                                      updateRouteFn(route);
                                  }}
                                  snapDistance={snapDistance}
                                  setSnapDistance={setSnapDistance}
                                  deleteTooltip={selectedEndWpIdx > selectedStartWpIdx ? `Delete ${selectedEndWpIdx - selectedStartWpIdx} waypoints` : selectedMarker ? `Delete stop ${selectedMarker.stopName}` : null}
                                  handleDelete={() => {
                                      if (selectedStartWpIdx > -1 && selectedEndWpIdx >= selectedStartWpIdx) {
                                          if (singleWpSelected()) {
                                              route.waypoints.splice(selectedStartWpIdx, 1);
                                              updateRoute(route);
                                          } else {
                                              if (handleCut) {
                                                  handleCut(selectedStartWpIdx, selectedEndWpIdx);
                                              } else {
                                                  cut(selectedStartWpIdx, selectedEndWpIdx);
                                              }
                                              singleWpSelected(selectedStartWpIdx - 1);
                                          }
                                      } else if (selectedMarker) {
                                          deleteSelectedStop(selectedMarker);
                                      }
                                      setSelectedPt(null);
                                      setSelectedMarker(null);
                                      setSelectedMarkers([]);
                                      setSelectedStop(null);
                                  }}
                                  extendToTooltip={(selectedMarker || selectedPt) && selectedEndWpIdx > selectedStartWpIdx ? `Divert route to ${selectedMarker ? selectedMarker.stopName : 'point'} between selected waypoints` : selectedMarker ? `Extend route to ${selectedMarker ? selectedMarker.stopName : 'point'}` : null}
                                  handleRemoveStop={() => {
                                      deleteSelectedStop(selectedMarker);
                                      updateRouteFn(route);
                                      setSelectedPt(null);
                                      setSelectedStartWpIdx(0);
                                      setSelectedEndWpIdx(0);
                                      setSelectedMarker(null);
                                      setSelectedMarkers([]);
                                  }}
                                  addStopTooltip={selectedMarker ? `Add stop ${selectedMarker.stopName} to stopping pattern` : null}
                                  handleAddStop={async (selectedMarker, buildToStop) => {
                                      await addSelectedStop(route, selectedMarker, 'add', false, buildToStop);
                                      setSelectedPt(null);
                                      setSelectedStartWpIdx(0);
                                      setSelectedEndWpIdx(0);
                                      setSelectedMarker(null);
                                      setSelectedMarkers([]);
                                  }}
                                  handleCutWps={() => {
                                      if (handleCut) {
                                          handleCut(selectedStartWpIdx, selectedEndWpIdx);
                                      } else {
                                          cut(selectedStartWpIdx, selectedEndWpIdx);
                                      }
                                      setSelectedPt(null);
                                      setSelectedStartWpIdx(0);
                                      setSelectedEndWpIdx(0);
                                      setSelectedMarker(null);
                                      setSelectedMarkers([]);
                                  }}
                                  handleNewWaypoint={() => {
                                      createWaypoint();
                                      setSelectedPt(null);
                                      updateRouteFn(route);
                                      setSelectedStartWpIdx(0);
                                      setSelectedEndWpIdx(0);
                                      setSelectedMarker(null);
                                      setSelectedMarkers([]);
                                      // setFeatures(toGeoJson(route));
                                  }}
                                  canTrim={() => canTrimSelectedMarker()}
                                  handleTrim={(truncate, selectedStop) => {
                                      // if (handleTrim) {
                                      //     handleTrim(truncate, selectedMarker)
                                      // } else {
                                      trim(truncate, selectedStop);
                                      // }
                                      setSelectedPt(null);
                                      setSelectedStartWpIdx(0);
                                      setSelectedEndWpIdx(0);
                                      setSelectedMarker(null);
                                      setSelectedMarkers([]);
                                  }}
                                  handleExtendRoute={async (method) => {
                                      await handleBuildRoute(method, true);
                                      // if (selectedMarker) {
                                      //     await addSelectedStop(selectedMarker, selectedStartWpIdx !== selectedEndWpIdx ? 'insert' : 'append');
                                      //     setSelectedPt(null)
                                      // } else if (selectedPt) {
                                      //     if (selectedStartWpIdx !== selectedEndWpIdx) {
                                      //         await insertPathBetweenPt(selectedPt, selectedStartWpIdx, selectedEndWpIdx)
                                      //     } else {
                                      //         await appendPathToPt(selectedPt);
                                      //     }
                                      //     setSelectedMarker(null)
                                      // }
                                      setSelectedStartWpIdx(0);
                                      setSelectedEndWpIdx(0);
                                      setSelectedMarker(null);
                                      setSelectedMarkers([]);
                                  }}
                                  viewState={viewState}
                                  handleSnapToRoadFn={async () => {
                                      setIsSnapping(true);
                                      try {
                                          setSelectedPt(null);
                                          setSelectedStartWpIdx(0);
                                          setSelectedEndWpIdx(0);
                                          setSelectedMarker(null);
                                          setSelectedMarkers([]);
                                          const _route = cloneDeep(route);
                                          await handleSnapViewToRoad(_route);
                                          updateRoute(_route);
                                      } catch (e) {
                                          console.log(e);
                                          messageApi.open({
                                              type: 'error',
                                              duration: 5,
                                              content: 'Snap to road failed. Please check your waypoints are within the snap to road distance.',
                                          });
                                      }
                                      setIsSnapping(false);
                                  }}
                                  editing={editingRoute}
                                  setEditingRoute={setEditingRoute}
                                  wpsSelected={wpsSelected}
                                  singleWpSelected={singleWpSelected}
                                  canBuild={canEnableBuild}
                                  setShowNavigation={setShowNavigation}
                                  showNavigation={showNavigation}
                                  addCheckpoints={addCheckpoints}
                                  calculatingCheckpoints={calculatingCheckpoints}
                                  setCheckpoint={setCheckpointOnWp}
                />
                <Tooltip title="Waypoint selector">
                    <MapWpSelectorToolbar wpIdxsInView={wpIdxsInView}
                                          routes={wpSelectorRoutes}
                                          trips={route.services && [find(route.services, {tripId: selectedTripId})]}
                                          setSelectedStartWpIdx={val => {
                                              setSelectedStartWpIdx(val);
                                              setSelectedMarker(null);
                                          }}
                                          setSelectedEndWpIdx={val => {
                                              setSelectedEndWpIdx(val);
                                              setSelectedMarker(null);
                                          }}
                                          selectedStartWpIdx={selectedStartWpIdx}
                                          selectedEndWpIdx={selectedEndWpIdx}/>
                </Tooltip>
                {loadingmasterStops && <div style={{position: 'relative', top: -63, right: -452}}>
                    <LoadMessage title={'Loading source system points...'} height={32}/>
                </div>}
            </DeckGL>
        </> : <LoadMessage message={'loading map...'} size={'lg'}/>}
    </div>);
}
